/* 
  ANA KAPSAYICI (Container)
  -------------------------------------------------------------- */
  .rotating-rectangle-container {
    min-height: 100vh;
    color: #333;
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    box-sizing: border-box;
  }
   
  /* 
    GİRİŞ EKRANI (Intro)
    -------------------------------------------------------------- */
  
  .rotating-rectangle-card {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    animation: fadeIn 1s ease-in-out;
  }
  
  .rotating-rectangle-card-body {
    background-color: #fcfcfc;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
  }
  
  /* Intro butonu */
  .rotating-rectangle-button {
    background-color: #11318f;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 3px 8px rgba(255,102,0,0.3);
  }
  
  .rotating-rectangle-button:hover {
    background-color: #11318f;
    box-shadow: 0 3px 12px rgba(255,102,0,0.5);
  }
  
  /* 
    OYUN ALANI (Game Play)
    -------------------------------------------------------------- */
  .rotating-rectangle-gameplay {
    padding: 50px 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* STATUS alanı (Timer ve Score) */
  .rotating-rectangle-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #ffffff;
    border: 1px solid #ff6600;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 20px;
  }
  
  /* CANVAS KONTEYNERİ */
  .rotating-rectangle-canvas-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: min(80vmin, 80vmax); 
    height: auto;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
  }
  
  .rotating-rectangle-game-canvas {
    width: 100%;
    height: auto;
    border: 2px solid #ffcc00;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    display: block;
  }
  
  /* 
    OYUN KONTROLLERİ (GameControls)
    -------------------------------------------------------------- */
  .rotating-rectangle-game-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    background-color: #fff;
    border: 1px solid #11318f;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
  }
  
  .rotating-rectangle-game-controls button {
    background-color: #11318f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 0.9rem;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 3px 8px rgba(255,102,0,0.3);
  }
  
  .rotating-rectangle-game-controls button:hover {
    background-color: #11318f;
    box-shadow: 0 3px 12px rgba(255,102,0,0.5);
  }
  
  /* 
    SCORE ve TIMER
    -------------------------------------------------------------- */
  .game-timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 80px;
  }
  
  .game-timer {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 5px;
    color: #333;
  }
  
  .timer-progress-bar {
    position: relative;
    width: 120px;
    height: 10px;
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .timer-progress {
    height: 100%;
    width: 0;
    background-color: #2ecc71;
    transition: width 0.3s;
  }
  
  .score-display-container {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
  }
  
  .score-icon {
    font-size: 1.5rem;
    color: #ff6600;
  }
  
  .score-text {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #333;
  }
  
  
  /* 
    MODAL STİLLERİ (GameOverModal)
    -------------------------------------------------------------- */
  /* inline <style> ile geliyor, isterseniz buraya taşıyabilirsiniz. */
  
  /* 
    ANİMASYON
    -------------------------------------------------------------- */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* 
    RESPONSIVE TASARIM
    -------------------------------------------------------------- */
  @media (max-width: 768px) {
    .rotating-rectangle-intro {
      padding: 20px;
    }
  
    .rotating-rectangle-card-body {
      padding: 20px;
    }
  
    .rotating-rectangle-title {
      font-size: 2rem;
    }
  
    .rotating-rectangle-button {
      width: 90%;
      max-width: 300px;
    }
  
    .rotating-rectangle-status {
      flex-direction: column;
      gap: 15px;
    }
  
    .rotating-rectangle-canvas-container {
      width: 90vw; 
    }
  
    .timer-progress-bar {
      width: 100px;
    }
  }
  
  @media (max-width: 480px) {
    .rotating-rectangle-title {
      font-size: 1.8rem;
    }
  
    .rotating-rectangle-description {
      font-size: 1rem;
    }
  
    .rotating-rectangle-instructions-title {
      font-size: 1.2rem;
    }
  
    .rotating-rectangle-instructions-list li {
      font-size: 0.9rem;
    }
  
    .rotating-rectangle-button {
      font-size: 1rem;
    }
  
    .game-timer {
      font-size: 1.2rem;
    }
  }
  