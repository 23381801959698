/* GENEL KAPSAYICI (Container) */
.cumleleri-oku-container {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
}


/* OYUN ALANI (GamePlay) */
.cumleleri-oku-gameplay {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Animasyon */
@keyframes cumleleri-oku-fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* ================= INSTRUCTIONS (Giriş Ekranı) ================== */
.cumleleri-oku-instructions-vertical {
  display: flex;
  flex-direction: column; 
  align-items: center;    
  width: 100%;
  max-width: 900px;
  margin: 0 auto;         
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
  text-align: center;  
}

.cumleleri-oku-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ff6600;
}

.cumleleri-oku-description {
  font-size: 1.1rem;
  margin-bottom: 30px;
  line-height: 1.6;
  color: #333;
  max-width: 600px;
}

.cumleleri-oku-instructions-box {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 600px;  
  margin-bottom: 20px;
}

.cumleleri-oku-instructions-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #ff6600;
  font-weight: bold;
}

.cumleleri-oku-instructions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  text-align: left;
}

.cumleleri-oku-instructions-list li {
  font-size: 0.95rem;
  margin-bottom: 10px;
  color: #555;
  line-height: 1.5;
}

.cumleleri-oku-button {
  background-color: #ff6600;
  color: #fff;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
  box-shadow: 0 3px 8px rgba(255,102,0,0.3);
}

.cumleleri-oku-button:hover {
  background-color: #e65c00;
  box-shadow: 0 3px 12px rgba(255,102,0,0.5);
}

/* RESPONSIVE (Instructions) */
@media (max-width: 600px) {
  .cumleleri-oku-title {
    font-size: 2rem;
  }
  .cumleleri-oku-instructions-box {
    max-width: 90%;
  }
}

/* ================= OYUN ALANI (Cümleler) ================== */
.cumleleri-oku-game-area {
  width: 60%;
  max-width: 600px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.cumleleri-oku-item {
  background-color: #f7f7f7;
  color: #333;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.5rem;
  max-width: 100%;
  text-align: center;
  animation: cumleleri-oku-fadeInUp 0.5s ease;
  width: fit-content;
}

.cumleleri-oku-item.highlight {
  background-color: #ffcc00;
  color: #000;
  font-weight: bold;
}

/* ================= TIMER ================== */
.cumleleri-oku-timer {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #ff6600;
}

/* ================= MODAL ================== */
.cumleleri-oku-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cumleleri-oku-modal-content {
  background: #ffffff;
  color: #333;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  max-width: 600px;
  width: 90%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cumleleri-oku-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
}

.cumleleri-oku-close-button:hover {
  color: #e74c3c;
}

.cumleleri-oku-button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* ================= STATUS & CONTROL SATIRLARI ================== */
.cumleleri-oku-status {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.cumleleri-oku-control-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

/* ================= OYUN KONTROLLERİ ================== */
.cumleleri-oku-game-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #fff; 
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
}

.cumleleri-oku-select {
  min-width: 150px;
  background-color: #eee;
  color: #333;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.cumleleri-oku-speed-control-container {
  display: flex;
  align-items: center;
  gap: 15px;

  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
}

.cumleleri-oku-speed-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #ff6600;
}

.cumleleri-oku-speed-display {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cumleleri-oku-speed-value {
  font-size: 1.2rem;
  font-weight: bold;
  min-width: 40px;
  text-align: center;
  background: #fafafa;
  padding: 5px 10px;
  border-radius: 5px;
  color: #333;
}

.cumleleri-oku-speed-range {
  width: 150px;
  cursor: pointer;
  outline: none;
}

/* ================== RESPONSIVE =================== */
@media (max-width: 768px) {
  .cumleleri-oku-status,
  .cumleleri-oku-control-row {
    flex-direction: column;
  }

  .cumleleri-oku-game-controls,
  .cumleleri-oku-speed-control-container {
    width: 100%;
    justify-content: center;
  }

  .cumleleri-oku-game-controls button,
  .cumleleri-oku-select {
    width: 100%;
    margin: 5px 0;
  }

  .cumleleri-oku-speed-value {
    font-size: 1.1rem;
  }

  .cumleleri-oku-game-area {
    width: 80%;
    height: 400px;
  }
}

@media (max-width: 600px) {
  .cumleleri-oku-title {
    font-size: 2rem;
  }
  .cumleleri-oku-game-area {
    width: 95%;
    height: 350px;
  }
}
