/* ===============================
   NOKTA TAKİP OYUNU ANA KAPSAYICI
   =============================== */
   .dot-tracking-container {
    width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #fafafa; /* Arka plan rengi */
  overflow: hidden; /* Taşmaları engelle */
  padding: 1rem;
  font-family: 'Poppins', sans-serif;
  color: #333;
   
  }
  
  .dot-tracking-card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .dot-tracking-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  
  /* ===============================
     ÜST KISIM - ZAMAN/SKOR/HEADER
     =============================== */
  /* Üst Status Alanı */
  .dot-tracking-status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  
  /* Timer alanı */
  .dot-tracking-timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dot-tracking-timer {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .dot-tracking-timer-progress-bar {
    width: 100%;
    max-width: 200px;
    background-color: #eee;
    height: 10px;
    border-radius: 5px;
  }
  
  .dot-tracking-timer-progress {
    height: 100%;
    border-radius: 5px;
  }
  
  /* Skor alanı */
  .dot-tracking-score-container {
    display: flex;
    align-items: center;
    font-size: 1rem;
    gap: 5px;
  }
  
  .dot-tracking-score-icon {
    color: #ff6600;
    font-size: 1.2rem;
  }
  
  .dot-tracking-score-text {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #333;
  }
  
  /* ===============================
     OYUN ALANI (DAİRE İÇİ HAREKET)
     =============================== */

  .dot-tracking-circle-container {
    width: min(80vmin, 80vmax);
    height: min(80vmin, 80vmax);
    position: relative;
    margin: 0 auto;
    border: 1px solid #11318f;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
  
  }
  
  /* SVG'yi kapsayıcının tamamına genişletiyoruz */
  .dot-tracking-circle-svg {
    width: 100%;
    height: 100%;
  }
  
  /* ===============================
     KONTROL BUTONLARI / HIZ AYARI
     =============================== */
  .dot-tracking-control-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  
  .dot-tracking-game-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    background-color: #ffffff;
    border: 1px solid #11318f;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
  }
  
  .dot-tracking-game-controls button {
    background-color: #11318f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 0.9rem;
    border-radius: 5px;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 3px 8px rgba(255,102,0,0.3);
  }
  
  .dot-tracking-game-controls button:hover {
    background-color: #e65c00;
    box-shadow: 0 3px 12px rgba(255,102,0,0.5);
  }
  
  .dot-tracking-speed-control-container {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #ffffff;
    border: 1px solid #11318f;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
  }
  
  .dot-tracking-speed-label {
    font-weight: bold;
    font-size: 0.9rem;
    color: #11318f;
  }
  
  .dot-tracking-speed-display {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .dot-tracking-speed-button {
    background-color: #11318f;
    color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dot-tracking-speed-button:hover {
    background-color: #e65c00;
  }
  
  .dot-tracking-speed-value {
    font-size: 1.2rem;
    font-weight: bold;
    min-width: 40px;
    text-align: center;
    background: #f9f9f9;
    padding: 5px 10px;
    border-radius: 5px;
    color: #333;
    border: 1px solid #ccc;
  }
  
  .dot-tracking-speed-instructions {
    font-size: 0.9rem;
    color: #666;
    text-align: center;
  }
  
  /* ===============================
     MODAL (OYUN BİTİŞ EKRANI)
     =============================== */
  .dot-tracking-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: dotModalFadeIn 0.3s ease-out;
  }
  
  @keyframes dotModalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .dot-tracking-modal-content {
    background: #ffffff;
    color: #333333;
    padding: 2rem;
    border-radius: 12px;
    text-align: center;
    max-width: 450px;
    width: 90%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    animation: dotModalFadeIn 0.3s ease-out;
  }
  
  .dot-tracking-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    color: #333333;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .dot-tracking-close-button:hover {
    color: #e74c3c;
  }
  
  .dot-tracking-modal-title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #ff6347;
  }
  
  .dot-tracking-modal-score,
  .dot-tracking-modal-message {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .dot-tracking-button-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .dot-tracking-btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  .dot-tracking-btn-primary {
    background-color: #ff6347;
    color: #ffffff;
  }
  .dot-tracking-btn-primary:hover {
    background-color: #ff4500;
  }
  
  .dot-tracking-btn-secondary {
    background-color: #6c757d;
    color: #ffffff;
  }
  .dot-tracking-btn-secondary:hover {
    background-color: #5a6268;
  }
  
  .dot-tracking-btn-outline {
    background-color: transparent;
    border: 2px solid #ff6347;
    color: #ff6347;
  }
  .dot-tracking-btn-outline:hover {
    background-color: #ff6347;
    color: #ffffff;
  }
  
  /* =========================================
     RESPONSIVE MEDIA QUERIES (ÖRNEKLER)
     ========================================= */
  @media (min-width: 1024px) {
    /* Örneğin daireyi biraz daha büyütmek isterseniz */
    .dot-tracking-circle-container {
      width: min(60vmin, 1000px);
      height: min(60vmin, 1000px);
    }
  
    .dot-tracking-status {
      gap: 3rem;
    }
  
    .dot-tracking-control-row {
      gap: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .dot-tracking-status,
    .dot-tracking-control-row {
      flex-direction: column;
    }
  
    .dot-tracking-game-controls, 
    .dot-tracking-speed-control-container {
      width: 90%;
      justify-content: center;
    }
  
    .dot-tracking-game-controls button {
      width: 100%;
      margin: 5px 0;
    }
  }
  