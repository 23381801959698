.theme-dark {
  --primary-color: #11318f;  
  --secondary-color: #B89D69; 
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --card-background: #2c2c2c;
  --button-background: #ff6600;
  --button-hover: #e65c00;
  --title-color: #ffcc00;
  --progress-bar-fill: #ffcc00;
  --progress-bar-bg: #333;
}

.theme-blue {
  --background-color: #e3f2fd;
  --text-color: #0d47a1;
  --card-background: #bbdefb;
  --button-background: #1e88e5;
  --button-hover: #1565c0;
  --progress-bar-fill: #1e88e5;
  --progress-bar-bg: #333;
}

.theme-green {
  --background-color: #e8f5e9;
  --text-color: #1b5e20;
  --card-background: #c8e6c9;
  --button-background: #43a047;
  --button-hover: #2e7d32;
  --progress-bar-fill: #43a047;
  --progress-bar-bg: #333;
}

.theme-red {
  --background-color: #fff5f5;
  --text-color: #b71c1c;
  --card-background: #ffcdd2;
  --button-background: #f44336;
  --button-hover: #d32f2f;
  --title-color: #ff6600;
  --progress-bar-fill: #d32f2f;
  --progress-bar-bg: #333;
  --progress-bar-background: #ccc;
}

/* Mor Tema */
.theme-purple {
  --background-color: #f3e5f5;
  --text-color: #4a148c;
  --card-background: #ce93d8;
  --button-background: #9c27b0;
  --button-hover: #7b1fa2;
  --title-color: #ff6600;
  --progress-bar-fill:#7b1fa2;
  --progress-bar-bg: #333;
  --progress-bar-background: #ccc;
}

.theme-orange {
  --background-color: #fff3e0;
  --text-color: #e65100;
  --card-background: #ffe0b2;
  --button-background: #fb8c00;
  --button-hover: #ef6c00;
  --title-color: #ff6600;
  --progress-bar-fill: #e65100;
  --progress-bar-bg: #333;
  --progress-bar-background: #ccc;
}

.theme-pink {
  --background-color: #fce4ec;
  --text-color: #880e4f;
  --card-background: #f8bbd0;
  --button-background: #ec407a;
  --button-hover: #d81b60;
  --title-color: #ff6600;
  --progress-bar-fill:  #d81b60;
  --progress-bar-bg: #333;
  --progress-bar-background: #ccc;
}
/* ======================================= */
/* 2) GENEL OYUN STİLLERİ (Tema ile)       */
/* ======================================= */

.tachisto-game-container {
  background-color: var(--background-color);
  min-height: 100vh;
  color: var(--text-color);
  padding: 20px;
}


/* ======================================= */
/* 3) ROTATINGDIAMOND STİLİ KLON (Intro)   */
/* ======================================= */

.tachisto-intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  animation: fadeInIntro 0.8s ease-in-out;
}

/* Gri Kart (RotatingDiamond) */
.tachisto-card {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

/* Kart gövdesi (daha açık gri) */
.tachisto-card-body {
  background-color: #fcfcfc;
  border-radius: 8px;
  text-align: center;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
  padding: 2em;
}

/* Başlık (Turuncu) */
.tachisto-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ff6600;
}

/* Açıklama Metni */
.tachisto-description {
  font-size: 1em;
  margin-bottom: 30px;
  color: #555;
  line-height: 1.5;
}

/* Talimat Başlık */
.tachisto-instructions-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #ff6600;
}

/* 
   Talimat Listesi (ul) 
   'arrow bullet' (▶) ekliyoruz
*/
.tachisto-instructions-list {
  text-align: left;
  font-size: 1em;
  margin-bottom: 30px;
  list-style: none;
  padding: 0;
}

/* Her li'de turuncu ok "▶" */
.tachisto-instructions-list li {
  position: relative;
  padding-left: 1.5em; /* Ok simgesi için boşluk */
  margin-bottom: 10px;
  align-items: flex-start;
  color: #000; /* Siyah yazı */
  line-height: 1.5;
}
.tachisto-instructions-list li::before {
  content: '▶';
  position: absolute;
  left: 0;
  top: 0.2em;
  color: #ff6600;
  font-size: 1.2em;
}

.tachisto-instructions-list li strong {
  color: #ff6600;
}

/* Intro Butonu (Turuncu) */
.tachisto-button {
  background-color: #ff6600;
  color: #fff;
  border: none;
  padding: 0.8em 1.5em;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.4em;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-weight: 600;
  box-shadow: 0 3px 8px rgba(255,102,0,0.3);
}
.tachisto-button:hover {
  background-color: #e65c00;
  box-shadow: 0 3px 12px rgba(255,102,0,0.5);
}

/* Intro Fade Animasyonu */
@keyframes fadeInIntro {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ======================================= */
/* 4) OYUN ALANI (Tema Değişkenleri ile)   */
/* ======================================= */

.tachisto-gameplay {
  margin: 20px auto;
  max-width: 800px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.5);
  background-color: var(--card-background);
  position: relative;
  padding: 2em;
  font-size: inherit;
}

/* Kelime Gösterim */
.tachisto-word-display {
  font-size: 3em;
  font-weight: 300;
  font-family: 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: var(--text-color);;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  animation: slideIn 0.5s ease-out;
}
@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  60% {
    transform: translateY(10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}

/* Oyun Kontrolleri (Tema Renkleri) */
.tachisto-game-controls {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
}
.tachisto-game-controls button,
.tachisto-game-controls select {
  background-color: var(--button-background);
  color: #fff;
  border: none;
  padding: 0.6em 1em;
  font-size: inherit;
  border-radius: 5px;
  margin: 0.3em;
  cursor: pointer;
  transition: background-color 0.3s;
}
.tachisto-game-controls button:hover,
.tachisto-game-controls select:hover {
  background-color: var(--button-hover);
}

/* Speed Control Label & Range */
.tachisto-speed-control {
  display: flex;
  align-items: center;
  color: #fff;
  margin: 5px;
  font-size: inherit;
}
.tachisto-speed-control label {
  margin-right: 10px;
}
.tachisto-speed-control input[type="range"] {
  width: 150px;
}

/* Input Alanı */
.tachisto-input-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.tachisto-input {
  border-radius: 15px;
  padding: 0.8em;
  font-size: inherit;
  width: 100%;
  max-width: 500px;
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
}
.tachisto-input::placeholder {
  color: #aaa;
}

/* Girilen Kelimeler */
.tachisto-entered-words {
  margin-top: 20px;
  color: var(--title-color);
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
}
.tachisto-entered-words h5 {
  margin-bottom: 10px;
}

/* Progress Bar */
.tachisto-progress-bar-container {
  width: 80%;
  max-width: 1200px;
  background-color: var(--progress-bar-background);
  border-radius: 10px;   
  overflow: hidden;
  margin: 20px auto;
  border: 2px solid var(--border-color);
  box-shadow: 0 0 10px var(--border-color);
  height: 30px;  
  letter-spacing: 1px;  
}

.tachisto-progress-bar-fill {
  height: 100%;
  background-color: var(--progress-bar-fill);
  width: 0%;
  transition: width 0.5s ease;
  letter-spacing: 1px;
}



/*SETTİNGS*/
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.modal-content {
  background: #2c3e50;
  color: #ecf0f1;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: fadeIn 0.3s ease-out;
}

/* Kapatma Butonu */
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #ecf0f1;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
}
.close-button:hover {
  color: #e74c3c;
}

/* Responsive Tasarım */
@media (max-width: 768px) {
  .tachisto-intro-container {
    padding: 20px;
  }
  .tachisto-title {
    font-size: 1.5em;
  }
  .tachisto-card-body {
    padding: 1em;
  }
  .tachisto-game-controls {
    flex-direction: column;
  }
  .tachisto-game-controls button,
  .tachisto-game-controls select {
    width: 100%;
  }
  .tachisto-word-display {
    font-size: 2em;
  }
}
