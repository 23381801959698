.theme-red {
  --background-color: #fff5f5;
  --text-color: #b71c1c;
  --card-background: #ffcdd2;
  --button-background: #f44336;
  --button-hover: #d32f2f;
  --title-color: #ff6600;
}

/* Mor Tema */
.theme-purple {
  --background-color: #f3e5f5;
  --text-color: #4a148c;
  --card-background: #ce93d8;
  --button-background: #9c27b0;
  --button-hover: #7b1fa2;
  --title-color: #4a148c;
}

/* Turuncu Tema */
.theme-orange {
  --background-color: #fff3e0;
  --text-color: #e65100;
  --card-background: #ffe0b2;
  --button-background: #fb8c00;
  --button-hover: #ef6c00;
  --title-color: #ff6600;
}

/* Pembe Tema */
.theme-pink {
  --background-color: #fce4ec;
  --text-color: #880e4f;
  --card-background: #f8bbd0;
  --button-background: #ec407a;
  --button-hover: #d81b60;
  --title-color: #880e4f;
}

.theme-dark {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --card-background: #2c2c2c;
  --button-background: #ff6600;
  --button-hover: #e65c00;
  --title-color: #ffcc00;
}

.theme-blue {
  --background-color: #e3f2fd;
  --text-color: #0d47a1;
  --card-background: #bbdefb;
  --button-background: #1e88e5;
  --button-hover: #1565c0;
  --title-color: #0d47a1;
}

/* Yeşil Tema */
.theme-green {
  --background-color: #e8f5e9;
  --text-color: #1b5e20;
  --card-background: #c8e6c9;
  --button-background: #43a047;
  --button-hover: #2e7d32;
  --title-color: #1b5e20;
}

.harf-izgarasi-container.theme-default,
.harf-izgarasi-intro-container.theme-default {  

  --background-color: transparent;
  --text-color: #333;
  --card-background: #f2f2f2;
  --title-color: #ff6600;
}

.harf-izgarasi-container {
  background-color: var(--background-color);
  min-height: 100vh;
  color: var(--text-color);
  padding: 20px;
}


.harf-izgarasi-card {
  background-color: var(--card-background);
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.harf-izgarasi-card-body {
  padding: 2.5em;
  text-align: center;
}


/* 
 * ===============================
 * DEĞİŞTİRİLEN: Talimat Bölümü
 * ===============================
 */

/* Bir "kutu" halinde ekrana yaslayalım */
.harf-izgarasi-instructions {
  width: 100%; /* tam genişlik */
  margin-bottom: 2em;
  text-align: left; /* sola yasla */
  padding: 1em 1.5em;
  background-color: rgba(255, 255, 255, 0.8); /* beyaz yarı saydam */
  border-left: 6px solid var(--title-color);   /* sol kenarda renkli şerit */
  border-radius: 4px;
}

/* Talimat başlık */
.harf-izgarasi-instructions-title {
  font-size: 1.5em;
  margin-bottom: 0.8em;
  color: var(--title-color);
  text-transform: uppercase;  /* daha vurgulu */
  letter-spacing: 1px;        /* harf aralığı */
}

/* Liste */
.harf-izgarasi-instructions-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.harf-izgarasi-instructions-list li {
  font-size: 1em; 
  margin-bottom: 0.8em;
  display: flex;
  gap: 0.5em;
  align-items: center;        
  position: relative;         

}

.harf-izgarasi-instructions-list li::before {
  content: "▶";             
  color: var(--title-color);
  font-size: 1.1em;
  margin-right: 0.2em;       
}

.harf-izgarasi-instructions-list li strong {
  color: var(--title-color);
  font-weight: 600;
}

.harf-izgarasi-button {
  background-color: var(--button-background);
  color: #fff;
  border: none;
  padding: 0.8em 1.5em;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.4em;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-weight: 600;
  box-shadow: 0 3px 8px rgba(255, 102, 0, 0.3);
}

.harf-izgarasi-button:hover {
  background-color: var(--button-hover);
  box-shadow: 0 3px 12px rgba(255, 102, 0, 0.5);
}



/* 
 * ============================
 * 6) Oyun Alanı (Gameplay)
 * ============================
 */
.harf-izgarasi-gameplay {
  padding: 3em 1.5em;
}

/* 6.1) Oyun Kontrolleri */

/* 
   DEĞİŞTİRİLEN: Oyun kontrol butonlarının font-size 0.8rem => 1rem
*/
.harf-izgarasi-game-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
}

.harf-izgarasi-game-controls-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.harf-izgarasi-game-controls-row button,
.harf-izgarasi-game-controls-row select {
  font-size: 1rem;  
  padding: 8px 12px; 
  margin: 0;
  border: none;
  border-radius: 4px;
  background-color: var(--button-background);
  color: #fff;
  transition: background-color 0.3s;
}

.harf-izgarasi-game-controls-row button:hover,
.harf-izgarasi-game-controls-row select:hover {
  background-color: var(--button-hover);
}

/* 
 * ================================
 * 6.3) Letter Grid Container
 * ================================
 */
.letter-grid-container {
  max-width: 90vmin;
  max-height: 90vmin;
  border: 1px solid #ff6600;
  border-radius: 8px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  background-color: var(--card-background);
}

@media (min-width: 1024px) {
  .letter-grid-container {
    max-width: 80vmin;
    max-height: 80vmin;
  }
}

.harf-izgarasi-letter-grid {
  display: grid;
  gap: 0.6em;
  width: auto;
  height: auto;
  justify-items: center;
  align-items: center;
  padding: 1em;
}

/* 
 * =========================================
 * 6.4) Harf kutuları 
 * =========================================
 */
.harf-izgarasi-letter {
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #555;
  border-radius: 8px;
  background-color: var(--card-background);
  transition: background-color 0.3s ease, transform 0.2s ease;
  user-select: none;
  line-height: 1;
  aspect-ratio: 1/1;
  min-width: 3em;
  min-height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.harf-izgarasi-letter:hover {
  background-color: #444;
  transform: scale(1.05);
}

/* Doğru/yanlış animasyonları */
.harf-izgarasi-letter.correct {
  background-color: #4CAF50;
  color: #fff;
  border-color: #4CAF50;
  animation: correctAnimation 0.5s forwards;
}

.harf-izgarasi-letter.incorrect {
  background-color: #F44336;
  color: #fff;
  border-color: #F44336;
  animation: incorrectAnimation 0.5s forwards;
}

@keyframes correctAnimation {
  0%   { transform: scale(1); }
  50%  { transform: scale(1.2); }
  100% { transform: scale(1); }
}

@keyframes incorrectAnimation {
  0%   { transform: scale(1); }
  50%  { transform: scale(0.8); }
  100% { transform: scale(1); }
}

/* Hedef Harfler */
.harf-izgarasi-target-letter {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--text-color);
  border: 2px dashed var(--text-color);
  padding: 0.5em 1em;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 0.8rem;
}

/* Doğru/yanlış feedback mesajı */
.harf-izgarasi-message {
  font-size: inherit;
  margin-top: 1.2em;
  text-align: center;
  color: var(--title-color);
  animation: fadeInOut 1s ease-in-out;
}

@keyframes fadeInOut {
  0%   { opacity: 0; }
  10%  { opacity: 1; }
  90%  { opacity: 1; }
  100% { opacity: 0; }
}

/* 
 * ============================
 * 7) Responsive
 * ============================
 */
@media (max-width: 768px) {
  .harf-izgarasi-intro-container {
    padding: 1.5em;
  }

  .harf-izgarasi-intro {
    padding: 1.5em;
  }

  .harf-izgarasi-card-body {
    padding: 1.5em;
  }

  .harf-izgarasi-title {
    font-size: 1.5em;
  }

  /* Min boyutları biraz düşürüyoruz ki mobilde sığsın */
  .harf-izgarasi-letter {
    min-width: 2.5em;
    min-height: 2.5em;
  }

  .harf-izgarasi-target-letter {
    font-size: 1em;
  }

  /* Header & Kontroller mobilde üst üste gelsin */
  .harf-izgarasi-header-buttons {
    flex-direction: column;
    gap: 0.4rem;
  }

  .harf-izgarasi-game-controls {
    gap: 0.4rem;
  }

  .harf-izgarasi-game-controls-row {
    justify-content: center;
  }

  .harf-izgarasi-timer-score {
    flex-direction: column;
    gap: 0.6em;
  }
}
