  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }  

  .kt-theme-default { 
    --primary-color: #11318f;  
    --secondary-color: #B89D69; 
    --button-text-color: #FFFFFF; 
    --oyun-alani: #f8f8f8;
    --background-color: #f5f5f5;
    --text-color: #11318f;
    --card-background: #ffffff;
    --button-background: #007bff;
    --button-hover: #0056b3; 
    --progress-bar-fill: #007bff;
    --accent-color: #0d6efd; 
    --title-color: var(--primary-color);
    --border-color: var(--primary-color);
    --progress-bar-bg: #333; 
    --progress-bar-fill: var(--primary-color);
  }
    
  /* Kırmızı Tema */
  .kt-theme-red {
    --background-color: #fff5f5;
    --text-color: #b71c1c;
    --card-background: #ffcdd2;
    --button-background: #f44336;
    --button-hover: #d32f2f;
    --title-color: #ff6600;
    --progress-bar-bg: #ffcdd2;
    --progress-bar-fill: #f44336;
  }
  
  /* Mor Tema */
  .kt-theme-purple {
    --background-color: #f3e5f5;
    --text-color: #4a148c;
    --card-background: #ce93d8;
    --button-background: #9c27b0;
    --button-hover: #7b1fa2;
    --title-color: #ff6600;
    --progress-bar-bg: #ce93d8;
    --progress-bar-fill: #9c27b0;
  }
  
  /* Turuncu Tema */
  .kt-theme-orange {
    --background-color: #fff3e0;
    --text-color: #e65100;
    --card-background: #ffe0b2;
    --button-background: #fb8c00;
    --button-hover: #ef6c00;
    --title-color: #ff6600;
    --progress-bar-bg: #ffe0b2;
    --progress-bar-fill: #fb8c00;
  }
  
  /* Pembe Tema */
  .kt-theme-pink {
    --background-color: #fce4ec;
    --text-color: #880e4f;
    --card-background: #f8bbd0;
    --button-background: #ec407a;
    --button-hover: #d81b60;
    --title-color: #ff6600;
    --progress-bar-bg: #f8bbd0;
    --progress-bar-fill: #ec407a;
  }
  
  /* Koyu Tema */
  .kt-theme-dark {
    --background-color: #1a1a1a;
    --text-color: #ffffff;
    --card-background: #2c2c2c;
    --button-background: #ff6600;
    --button-hover: #e65c00;
    --title-color: #ffcc00;
    --progress-bar-bg: #333;
    --progress-bar-fill: #ffcc00;
  }
  
  
  /* Mavi Tema */
  .kt-theme-blue {
    --background-color: #e3f2fd;
    --text-color: #0d47a1;
    --card-background: #bbdefb;
    --button-background: #1e88e5;
    --button-hover: #1565c0;
    --title-color: #ff6600;
    --progress-bar-bg: #90caf9;
    --progress-bar-fill: #1e88e5;
  }
  
  /* Yeşil Tema */
  .kt-theme-green {
    --background-color: #e8f5e9;
    --text-color: #1b5e20;
    --card-background: #c8e6c9;
    --button-background: #43a047;
    --button-hover: #2e7d32;
    --title-color: #ff6600;
    --progress-bar-bg: #a5d6a7;
    --progress-bar-fill: #43a047;
  }

  .kh-theme-tomer {
    --primary-color: #274989;  
    --secondary-color: #B89D69;     
    --accent-color: #FFDD57;  
    --background-color: #F9FAFB;  
    --card-background: #FFFFFF;   
    --text-color: var(--primary-color);  
    --button-background: var(--primary-color);
    --button-hover: #3B3498; 
    --button-text-color: #FFFFFF;
    --title-color: var(--secondary-color);
    --border-color: var(--primary-color);
    --progress-bar-bg: #333;
    --progress-bar-fill: var(--secondary-color);
    --oyun-alani: #f8f8f8;
  }
  
  /******************************************************
    GENEL YAPI (Mobil Öncelikli)
  ******************************************************/
  .kt-container {
    background-color: var(--background-color);
    min-height: 100vh;
    color: var(--text-color);
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
  }
  
  /* =============================
     2) INTRO EKRANI (Klon Tasarım)
     ============================= */
  .kt-intro-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3em;
    background-color: var(--background-color);
  }
  
  .kt-intro {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3em;
  }
  
  .kt-card {
    background-color: var(--card-background, #6994e2);
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .kt-card-body {
    padding: 2.5em;
    text-align: center;
  }
  
  .kt-title {
    font-size: 2em;
    margin-bottom: 0.8em;
    color: var(--title-color);
  }
  
  .kt-description {
    font-size: inherit;
    margin-bottom: 2em;
  }
  
  
  
  /******************************************************
    OYUN EKRANI
  ******************************************************/
  .kt-game-display {
    background-color: var(--card-background);
    padding: 20px;
    border-radius: 10px;
    color: var(--text-color);
    font-size: inherit;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .kt-options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .kt-options-container button {
    background-color: #333;
    color: #fff;
    border: none;
    font-size: inherit;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s;
  }
  .kt-options-container .btn-primary,
.kt-options-container .btn-outline-primary {
  font-weight: bold;
  color: var(--text-color, #fff);
}

  .kt-options-container button:hover {
    background-color: #444;
  }
  
  .kt-options-container .btn-primary {
    background-color: var(--button-background);
  }
  .kt-options-container .btn-primary:hover {
    background-color: var(--button-hover);
  }
  
  .kt-blank-button {
    background-color: #ffff99;
    color: #000;
    font-weight: bold;
    font-size: inherit;
    border: none;
    margin: 0 2px;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .kt-blank-button:hover {
    background-color: #ffeb3b;
  }
  
  .kt-blank-button.correct {
    background-color: #4caf50;
    color: #fff;
    cursor: default;
  }
  
  .kt-blank-button.incorrect {
    background-color: #f44336;
    color: #fff;
  }
  
  /******************************************************
    MESSAGE (Bilgilendirme Metni)
  ******************************************************/
  .kt-message-display {
    font-size: 1.2rem;
    margin-top: 20px;
    text-align: center;
    color: var(--title-color);
  }
  
  /******************************************************
    SPEED CONTROL (Örneğin hız ayarı paneli)
  ******************************************************/
  .kt-speed-control-container {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: var(--card-background);
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
    justify-content: center;
    margin: 0 auto 20px;
  }
  
  .kt-speed-label {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  .kt-speed-display {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .kt-speed-button {
    background-color: var(--button-background);
    color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .kt-speed-button:hover {
    background-color: var(--button-hover);
  }
  
  .kt-speed-value {
    font-size: 1.2rem;
    font-weight: bold;
    min-width: 40px;
    text-align: center;
    background: #111;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
  }
  
  
  /******************************************************
    RESPONSIVE MEDIA QUERIES
  ******************************************************/
  
  /* 768px ve aşağısı (Mobil) */
  @media (max-width: 768px) {
    .kt-card-body {
      padding: 1.5em;
    }
  
    .kt-title {
      font-size: 1.5em;
    }
  
    .kt-btn {
      width: auto;
    }
  }
  
  /* 1024px ve üstü (Büyük Ekranlar) */
  @media (min-width: 1024px) {
    .kt-container {
      width: 100%;
      min-height: 100vh;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  
    .kt-card {
      width: 100%;
      max-width: 1200px;
      margin: 30px auto;
    }
  
    .kt-card-body {
      padding: 40px 50px;
    }
  
    .kt-title {
      font-size: 3rem;
    }
  
    .kt-btn {
      font-size: 1.2rem;
      padding: 15px 40px;
      margin: 10px;
    }
  
    .kt-game-display {
      font-size: 1.4rem;
    }
  
    .kt-message-display {
      font-size: 1.5rem;
    }
  }
  