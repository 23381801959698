html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  min-height: 100vh;
}
*, *::before, *::after {
  box-sizing: inherit;
}

.silinen-yazi-theme-default {
  --primary-color: #11318f;  
  --secondary-color: #B89D69; 
  --button-text-color: #FFFFFF; 
  --progress-bar-bg: #333; 
  --oyun-alani: #f8f8f8;
  --background-color: #f5f5f5;
  --text-color: #11318f;
  --card-background: #ffffff;
  --button-background: #007bff;
  --button-hover: #0056b3; 
  --accent-color: #0d6efd; 
  --title-color: var(--primary-color);
  --border-color: var(--primary-color);
  --progress-bar-fill: var(--primary-color);
}

.silinen-yazi-theme-tomer {
  --primary-color: #274989;  
  --secondary-color: #cfc1a4; 
  --accent-color: #FFDD57;  
  --background-color: #F9FAFB;  
  --card-background: #FFFFFF;   
  --text-color: var(--primary-color);;  
  --button-background: var(--primary-color);
  --button-hover: #3B3498; 
  --button-text-color: #FFFFFF;
  --title-color: var(--secondary-color);
  --border-color: var(--primary-color);
  --progress-bar-bg: #333;
  --progress-bar-fill: var(--secondary-color);
  --oyun-alani: #f8f8f8;
}

.silinen-yazi-theme-dark {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --card-background: #2c2c2c;
  --button-background: #ff6600;
  --button-hover: #e65c00;
  --progress-bar-background: #333;
  --progress-bar-fill: #ffcc00;
}

.silinen-yazi-theme-light {
  --background-color: #f5f5f5;
  --text-color: #1a1a1a;
  --card-background: #ffffff;
  --button-background: #007bff;
  --button-hover: #0056b3;
  --progress-bar-background: #ccc;
  --progress-bar-fill: #007bff;
}

/* Mavi Tema */
.silinen-yazi-theme-blue {
  --background-color: #e3f2fd;
  --text-color: #0d47a1;
  --card-background: #bbdefb;
  --button-background: #1e88e5;
  --button-hover: #1565c0;
  --progress-bar-background: #90caf9;
  --progress-bar-fill: #1e88e5;
}

/* Yeşil Tema */
.silinen-yazi-theme-green {
  --background-color: #e8f5e9;
  --text-color: #1b5e20;
  --card-background: #c8e6c9;
  --button-background: #43a047;
  --button-hover: #2e7d32;
  --progress-bar-background: #a5d6a7;
  --progress-bar-fill: #43a047;
}

/* Kırmızı Tema */
.silinen-yazi-theme-red {
  --background-color: #fff5f5;
  --text-color: #b71c1c;
  --card-background: #ffcdd2;
  --button-background: #e53935;
  --button-hover: #c62828;
  --progress-bar-background: #ef9a9a;
  --progress-bar-fill: #e53935;
}

/* Mor Tema */
.silinen-yazi-theme-purple {
  --background-color: #f3e5f5;
  --text-color: #4a148c;
  --card-background: #e1bee7;
  --button-background: #8e24aa;
  --button-hover: #6a1b9a;
  --progress-bar-background: #ce93d8;
  --progress-bar-fill: #8e24aa;
}

/* Turuncu Tema */
.silinen-yazi-theme-orange {
  --background-color: #fff3e0;
  --text-color: #e65100;
  --card-background: #ffe0b2;
  --button-background: #fb8c00;
  --button-hover: #ef6c00;
  --progress-bar-background: #ffb74d;
  --progress-bar-fill: #fb8c00;
}

/* Pembe Tema */
.silinen-yazi-theme-pink {
  --background-color: #fce4ec;
  --text-color: #880e4f;
  --card-background: #f8bbd0;
  --button-background: #ec407a;
  --button-hover: #d81b60;
  --progress-bar-background: #f48fb1;
  --progress-bar-fill: #ec407a;
}

/* ============================= */
/* ANA OYUN KAPSAYICISI (INTRO VE GAME) */
/* ============================= */
.silinen-yazi-game-container {
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  box-sizing: border-box;
}

.silinen-yazi-card {
  background-color: var(--card-background);
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.silinen-yazi-card-body {
  padding: 40px;
  text-align: center;
}

/* ================================= */
/* OYUN ALANI */
/* ================================= */
.silinen-yazi-gameplay {
  padding: 50px 20px;
}




/* ================================= */
/* OYUN EKRANI (METİN GÖSTERİM) */
/* ================================= */
.silinen-yazi-game-display {
  background-color: var(--card-background);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  line-height: 1.5;
  font-size: 1.5rem; /* React <div style={{ fontSize }} ile override */
  margin-bottom: 20px;
}

.silinen-yazi-content {
  width: 100%;
}

/* ================================= */
/* PROGRESS BAR */
/* ================================= */
.silinen-yazi-progress-bar-container {
  width: 100%;
  background-color: var(--progress-bar-background);
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0;
  height: 20px;
}

.silinen-yazi-progress-bar-fill {
  height: 20px;
  background-color: var(--progress-bar-fill);
  width: 0%;
  transition: width 0.2s ease-in-out;
}

/* ================================= */
/* BAŞLAT/DURAKLAT KONTROLLERİ */
/* ================================= */
.silinen-yazi-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.silinen-yazi-controls button {
  background-color: var(--button-background);
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.silinen-yazi-controls button:hover {
  background-color: var(--button-hover);
}

/* ================================= */
/* MESAJ (DURUM BİLGİSİ) */
/* ================================= */
.silinen-yazi-message {
  font-size: 1.2rem;
  margin-top: 20px;
  text-align: center;
  color: var(--button-background);
}

/* ================================= */
/* RESPONSIVE */
/* ================================= */

/* Tablet için */
@media (max-width: 1024px) {
  .silinen-yazi-title {
    font-size: 2.5rem;
  }

  .silinen-yazi-game-display {
    font-size: 1.3rem;
  }
}

/* Mobil için */
@media (max-width: 600px) {
  .silinen-yazi-title {
    font-size: 2rem;
  }

  .silinen-yazi-game-display {
    font-size: 1.1rem;
  }

  .silinen-yazi-game-controls {
    flex-direction: column;
    align-items: center;
  }

  .silinen-yazi-game-controls > div {
    width: 100%;
    margin-bottom: 15px;
  }
}



/* ================================= */
/* 1024px ve üzeri tam ekrana sığdırma */
/* ================================= */
@media (min-width: 1024px) {
  .silinen-yazi-game-container {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 20px; 
    box-sizing: border-box;
  }
}

/* ================================= */
/* MODAL STİLLERİ (Ayarlar) */
/* ================================= */
.silinen-yazi-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
}

.silinen-yazi-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background:
    linear-gradient(to bottom right, #3D8D7A, #3D8D7A),
    repeating-linear-gradient(
      45deg,
      rgba(224, 38, 5, 0.03),
      rgba(202, 116, 3, 0.03) 10px,
      transparent 10px,
      transparent 20px
    );
  background-repeat: no-repeat, repeat;
  background-size: 100% 100%, 20px 20px;
  background-blend-mode: multiply;
  color: var(--text-color, #1a1a1a);
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

/* =============================================== */
/* INTRO EKRANI İÇİN BEYAZ+TURUNCU TASARIM OVERRIDE */
/* =============================================== */


.silinen-yazi-card {
  background-color: #fff7eb !important; /* Açık turuncu arka plan */
  border-radius: 8px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.silinen-yazi-card-body {
  padding: 30px;
  text-align: center;
}



/* Responsive (isteğe bağlı ek ayarlar) */
@media (max-width: 600px) {

  
  .silinen-yazi-card-body {
    padding: 20px;
  }

}
