
  /* Koyu Tema */
  .theme-dark {
    --background-color: #1a1a1a;
    --text-color: #ffffff;
    --title-color: #2c2c2c;
    --card-background: #2c2c2c;
    --button-background: #ff6600;
    --button-hover: #e65c00;
    --title-color: #ffcc00;
    --progress-bar-bg: #333;
    --progress-bar-fill: #ffcc00;
    --oyun-alani: #0a3631;
  }
  
  /* Mavi Tema */
  .theme-blue {
    --background-color: #e3f2fd;
    --text-color: #7a9fd6;
    --card-background: #bbdefb;
    --button-background: #1e88e5;
    --button-hover: #1565c0;
    --title-color: #ff6600;
    --progress-bar-bg: #90caf9;
    --progress-bar-fill: #1e88e5;
    --oyun-alani: #1d4cac;
  }
  
  /* Yeşil Tema */
  .theme-green {
    --background-color: #e8f5e9;
    --text-color: #1b5e20;
    --card-background: #c8e6c9;
    --button-background: #43a047;
    --button-hover: #2e7d32;
    --title-color: #ff6600;
    --progress-bar-bg: #a5d6a7;
    --progress-bar-fill: #43a047;
    --oyun-alani: #0dec58;
  }
  
  /* Kırmızı Tema */
  .theme-kirmizi {
    --background-color: #fce4ec;
    --text-color: #fcf9f9;
    --card-background: #d31658;
    --button-background: #c41616;
    --button-hover: #dd3d3d;
    --title-color: #ad0909;
    --progress-bar-bg: #f8bbd0;
    --progress-bar-fill: #ec407a;
    --oyun-alani: #c40616;
  }
  
  /* Mor Tema */
  .theme-purple {
    --background-color: #f3e5f5;
    --text-color: #4a148c;
    --card-background: #ce93d8;
    --button-background: #9c27b0;
    --button-hover: #7b1fa2;
    --title-color: #ff6600;
    --progress-bar-bg: #ce93d8;
    --progress-bar-fill: #9c27b0;
  }
  
  /* Turuncu Tema */
  .theme-orange {
    --background-color: #fff3e0;
    --text-color: #e65100;
    --card-background: #ffe0b2;
    --button-background: #fb8c00;
    --button-hover: #ef6c00;
    --title-color: #ff6600;
    --progress-bar-bg: #ffe0b2;
    --progress-bar-fill: #fb8c00;
  }
  
  /* Pembe Tema */
  .theme-pink {
    --background-color: #fce4ec;
    --text-color: #880e4f;
    --card-background: #f8bbd0;
    --button-background: #ec407a;
    --button-hover: #d81b60;
    --title-color: #ff6600;
    --progress-bar-bg: #f8bbd0;
    --progress-bar-fill: #ec407a;
  }
  

  /******************************************************
   *******************  GENEL YAPI  *********************
   ******************************************************/
  .timer span{
    color:var(--title-color);
  }
  .kbo-oyun-container {
    min-height: 100vh;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  
  .kbo-card {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .kbo-card-body {
    background-color: #fcfcfc;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
  }
  

  
  /******************************************************
   **************  GAME BOARD / WORD STYLE  *************
   ******************************************************/
  
  .kbo-kelime-bulmaca-game-container {
   background-color:  var(--card-background,  rgba(255, 102, 0, 0.9));  
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  line-height: 1;
  font-size: var(--font-size, 1rem);

  }
  
  .kbo-word {
    display: inline-block;
    padding: 5px;
    margin: 3px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .kbo-word:hover {
    background-color: #444;
    transform: scale(1.05);
  }
  
  .kbo-word.correct {
    background-color: #4caf50;
    color: #fff;
  }
  
  .kbo-word.incorrect {
    background-color: #f44336;
    color: #fff;
  }
  
  /* Hedef kelimeler */
  .kbo-target-words-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .kbo-target-word {
    background-color: #ffcc00;
    padding: 8px 15px;
    border-radius: 20px;
    font-weight: bold;
    color: #1a1a1a;
    position: relative;
    font-size: 1.5rem;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .kbo-target-word.found {
    background-color: #4caf50;
    color: #fff;
  }
  

  
  /******************************************************
   *********************  MESAJ  ************************
   ******************************************************/
  
  .kbo-message-display {
    font-size: 1.2rem;
    margin-top: 20px;
    text-align: center;
    color: var(--title-color);
  }
  
  /******************************************************
   *************  RESPONSIVE MEDIA QUERIES  *************
   ******************************************************/
  
  /* 768px ve aşağısı (Mobil) */
  @media (max-width: 768px) {
    .kbo-card-body {
      padding: 20px;
    }
  
    .kbo-title {
      font-size: 2rem;
    }
  
    .kbo-btn {
      width: 80%;
    }
  
    .kbo-game-controls {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .kbo-control-timer-container {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
  
  /* 1024px ve ÜSTÜ (Büyük Ekranlar) */
  @media (min-width: 1024px) {
    .kbo-oyun-container {
      min-height: 100vh;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .kbo-card {
      max-width: 1000px;
    }
  
    .kbo-card-body {
      padding: 50px;
    }
  
    .kbo-title {
      font-size: 3rem;
    }
  
    .kbo-btn {
      font-size: 1.2rem;
      padding: 15px 40px;
      margin: 10px;
    }
  
    .kbo-kelime-bulmaca-game-container {
      max-height: 600px;
      font-size: 1.8rem;
    }
  
  
    .kbo-message-display {
      font-size: 1.5rem;
    }
  }
  
  /* 
    TALİMATLAR (LISTE) STİLLERİ 
    ===========================
  */
  .kbo-instructions-title {
    color: #000; /* Başlık siyah */
  }
  
  .kbo-instructions-list {
    color: #000;       
    list-style: none;  
    padding-left: 0;   
  }
  
  .kbo-instructions-list li {
    color: #000; /* Liste maddeleri siyah */
  }
  