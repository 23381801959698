.hangi-sayi-gordun-container {

  min-height: 100vh;
  color: #333;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}


/*
  KART TASARIMI
  ----------------------------------------------------------------
*/
.hangi-sayi-gordun-card {
  /* Açık gri zemin + hafif gölge + ince çerçeve */
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  
  padding: 10px;
  animation: fadeIn 1s ease-in-out;
}

.hangi-sayi-gordun-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px; 
  padding: 20px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
  width: 100%;
}

/*
  OYUN ALANI
  ----------------------------------------------------------------
*/
.hangi-sayi-gordun-gameplay {
  margin-top: 20px;
}

/*
  AYAR KISMI - Zorluk Seviyesi, Gösterim Süresi
  ----------------------------------------------------------------
*/
.hangi-sayi-gordun-settings {
  display: flex;
  flex-wrap: wrap;      /* Yan yana sığmazsa alt satıra geçsin */
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;  
}

.hangi-sayi-gordun-setting {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  min-width: 180px;        
}


.hangi-sayi-gordun-setting input[type="range"] {
  width: auto;
  padding: 5px 10px;
  border-radius: 5px;
  border: 2px solid #ff6600;
  color: #333;
  background-color: #fafafa;
  margin: 0;
}

.hangi-sayi-gordun-setting select:focus,
.hangi-sayi-gordun-setting input[type="range"]:focus {
  outline: none;
  border-color: #e65c00;
}

.hangi-sayi-gordun-setting span {
  display: block;
  margin-top: 5px;
  color: #555;
  text-align: center;
}

.hangi-sayi-gordun-setting input[type="range"] {
  -webkit-appearance: none;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.hangi-sayi-gordun-setting input[type="range"]:hover {
  opacity: 1;
}

.hangi-sayi-gordun-setting input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background: #11318f;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
}

.hangi-sayi-gordun-setting input[type="range"]::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: #11318f;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
}

/*
  BUTONLAR
  ----------------------------------------------------------------
*/
.hangi-sayi-gordun-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.hangi-sayi-gordun-button {
  background-color: #11318f;
  color: #fff;
  border: none;
  padding: 10px 18px;   
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap; 
  height: auto;
}

.hangi-sayi-gordun-button:hover {
  background-color: #11318f;
}

/*
  STATUS (Kalan süre, skor vb.)
  ----------------------------------------------------------------
*/
.hangi-sayi-gordun-status {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  /* Kart benzeri görünüm için */
  background-color: #fff;
  border: 1px solid #11318f;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
  border-radius: 6px;
  padding: 8px;
  color: #333;
}

.hangi-sayi-gordun-status p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

/*
  OYUN CANVAS ALANI
  ----------------------------------------------------------------
*/
.hangi-sayi-gordun-canvas-container {
  position: relative;
  width: 400px;     
  height: 400px;
  margin: 0 auto 10px auto;
}

.hangi-sayi-gordun-canvas {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  border: 2px solid #11318f;
}

/*
  INPUT FORM (Overlay) - sayılar kaybolunca çıkan form
  ----------------------------------------------------------------
*/
.hangi-sayi-gordun-input-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.hangi-sayi-gordun-input-form input {
  width: 80%;
  max-width: 300px;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 10px;
  border: 2px solid #11318f;
  border-radius: 5px;
  background-color: #fafafa;
  color: #333;
}

.hangi-sayi-gordun-input-form input::placeholder {
  color: #999;
}

.hangi-sayi-gordun-input-form button {
  width: 50%;
  max-width: 150px;
  background-color: #11318f;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/*
  ANİMASYON
  ----------------------------------------------------------------
*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hangi-sayi-gordun-card {
  animation: fadeIn 1s ease-in-out;
}

/*
  RESPONSIVE
  ----------------------------------------------------------------
*/
@media (max-width: 768px) {
  .hangi-sayi-gordun-card {
    padding: 10px;
  }

  .hangi-sayi-gordun-card-body {
    padding: 15px;
  }

  .hangi-sayi-gordun-setting {
    flex-direction: column; 
    align-items: flex-start;  
  }

  .hangi-sayi-gordun-setting label {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .hangi-sayi-gordun-canvas-container {
    width: 100%;
    height: auto;
  }

  .hangi-sayi-gordun-canvas {
    width: 100%;
    height: 250px; 
  }

  .hangi-sayi-gordun-status {
    flex-direction: column;
    gap: 5px;
  }

  .hangi-sayi-gordun-status p {
    font-size: 1rem;
  }
}
