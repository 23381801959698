.nesneyi-takip-et-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #fafafa; 
  overflow: hidden; 
  padding: 1rem;
  font-family: 'Poppins', sans-serif;
  color: #333;
}


.nesneyi-takip-et-card {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  animation: fadeIn 1s ease-in-out;
}

.nesneyi-takip-et-card-body {
  background-color: #fcfcfc;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
}

/* --------------- OYUN ALANI (GAMEPLAY) ----------------- */
.nesneyi-takip-et-gameplay {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Status alanı: Timer + Score */
.nesneyi-takip-et-status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  background-color: #ffffff;
  border: 1px solid #11318f;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
}

/* Canvas alanı - responsive kare orantısı vermek için örnek kullanım */
.nesneyi-takip-et-canvas-container {
  width: min(80vmin, 80vmax);
  height: auto;
  margin-bottom: 20px;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
}

.nesneyi-takip-et-game-canvas {
  width: 100%;
  height: auto;
  display: block;
  border: 2px solid #11318f;
  border-radius: 10px;
  background-color: #fff;
}

/* Kontroller */
.nesneyi-takip-et-game-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #ffffff;
  border: 1px solid #11318f;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
  margin-bottom: 1rem;
}

.nesneyi-takip-et-game-controls button {
  background-color: #11318f;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin: 5px;
}

.nesneyi-takip-et-game-controls button:hover {
  background-color: #e65c00;
  box-shadow: 0 3px 12px rgba(255, 102, 0, 0.5);
}

.nesneyi-takip-et-game-controls span {
  font-size: 1rem;
  margin: 0 10px;
}

/* --------------- MODAL STİLLERİ ----------------- */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  background: #ffffff;
  color: #333333;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  max-width: 450px;
  width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #333333;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #e74c3c;
}

.modal-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: #ff6600;
}

.modal-score,
.modal-message {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.btn-primary {
  background-color: #11318f;
  color: #ffffff;
}
.btn-primary:hover {
  background-color: #e65c00;
}

.btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
}
.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-outline {
  background-color: transparent;
  border: 2px solid #11318f;
  color: #ff6600;
}
.btn-outline:hover {
  background-color: #ff6600;
  color: #ffffff;
}

/* --------------- SCORE DISPLAY ----------------- */
.score-display-container {
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 5px;
}

.score-icon {
  color: #ff6600;
  font-size: 1.2rem;
}

.score-text {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #333;
}

/* --------------- TIMER ----------------- */
.game-timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-timer {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.timer-progress-bar {
  width: 100%;
  max-width: 200px;
  background-color: #eee;
  height: 10px;
  border-radius: 5px;
}

.timer-progress {
  height: 100%;
  border-radius: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Orta boy ekranlar (örnek: tablet) */
@media (max-width: 768px) {
  .nesneyi-takip-et-status {
    flex-direction: column;
    gap: 15px;
  }

  .nesneyi-takip-et-game-controls {
    flex-direction: column;
    gap: 10px;
  }

  .nesneyi-takip-et-canvas-container {
    width: 90vw; /* Daha ufak cihazlarda 90vw yapabilirsiniz. */
  }

  .timer-progress-bar {
    max-width: 150px;
  }
}

/* Daha küçük ekranlar (örnek: mobil) */
@media (max-width: 480px) {
  .nesneyi-takip-et-title {
    font-size: 1.8rem;
  }

  .nesneyi-takip-et-description {
    font-size: 0.9rem;
  }

  .nesneyi-takip-et-instructions-title {
    font-size: 1.2rem;
  }

  .nesneyi-takip-et-instructions-list li {
    font-size: 0.85rem;
  }

  .nesneyi-takip-et-game-controls button {
    font-size: 0.8rem;
  }

  .score-display-container {
    font-size: 0.9rem;
  }

  .game-timer {
    font-size: 1rem;
  }

  .timer-progress-bar {
    max-width: 120px;
  }
}
