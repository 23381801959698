@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}

.rasgele-oyun-theme-default {
  --primary-color: #11318f;  
  --secondary-color: #B89D69; 
  --button-text-color: #FFFFFF; 
  --progress-bar-bg: #333; 
  --oyun-alani: #f8f8f8;
  --background-color: #f5f5f5;
  --text-color: #11318f;
  --card-background: #ffffff;
  --button-background: #007bff;
  --button-hover: #0056b3; 
  --accent-color: #0d6efd; 
  --title-color: var(--primary-color);
  --border-color: var(--primary-color);
  --progress-bar-fill: var(--primary-color);
}

.rasgele-oyun-theme-tomer {
  --primary-color: #274989;  
  --secondary-color: #cfc1a4; 
  --accent-color: #FFDD57;  
  --background-color: #F9FAFB;  
  --card-background: #FFFFFF;   
  --text-color: var(--primary-color);;  
  --button-background: var(--primary-color);
  --button-hover: #3B3498; 
  --button-text-color: #FFFFFF;
  --title-color: var(--secondary-color);
  --border-color: var(--primary-color);
  --progress-bar-bg: #333;
  --progress-bar-fill: var(--secondary-color);
  --oyun-alani: #f8f8f8;
}

.rasgele-oyun-theme-dark {
  --background-color: #0f0f0f;
  --text-color: #ffffff;
  --card-background: #1a1a1a;
  --button-background: #ff6600;
  --button-hover: #ff7711;
  --border-color: #ffcc00;
  --progress-bar-background: #333;
  --progress-bar-fill: #ffcc00;
  --accent-color: #00ffff;
}

.rasgele-oyun-theme-light {
  --background-color: #f5f5f5;
  --text-color: #11318f;
  --card-background: #ffffff;
  --button-background: #007bff;
  --button-hover: #0056b3;
  --border-color: #11318f;
  --progress-bar-background: #ccc;
  --progress-bar-fill: #007bff;
  --accent-color: #0d6efd; 
}

.rasgele-oyun-theme-blue {
  --background-color: #e3f2fd;
  --text-color: #0d47a1;
  --card-background: #bbdefb;
  --button-background: #1e88e5;
  --button-hover: #1565c0;
  --border-color: #1e88e5;
  --progress-bar-background: #90caf9;
  --progress-bar-fill: #1e88e5;
  --accent-color: #00ffff; 
}

.rasgele-oyun-theme-green {
  --background-color: #e8f5e9;
  --text-color: #1b5e20;
  --card-background: #c8e6c9;
  --button-background: #43a047;
  --button-hover: #2e7d32;
  --border-color: #43a047;
  --progress-bar-background: #a5d6a7;
  --progress-bar-fill: #43a047;
  --accent-color: #00ffff;
}

.rasgele-oyun-theme-red {
  --background-color: #fff5f5;
  --text-color: #b71c1c;
  --card-background: #ffcdd2;
  --button-background: #e42e21;
  --button-hover: #d32f2f;
  --title-color: #ec0b29;
  --progress-bar-bg: #ffcdd2;
  --progress-bar-fill: #f44336;
}

  .rasgele-oyun-theme-purple {
    --background-color: #f3e5f5;
    --text-color: #4a148c;
    --card-background: #ce93d8;
    --button-background: #9c27b0;
    --button-hover: #7b1fa2;
    --title-color: #ff6600;
    --progress-bar-bg: #ce93d8;
    --progress-bar-fill: #9c27b0;
  }


    .rasgele-oyun-theme-orange {
      --background-color: #fff3e0;
      --text-color: #e65100;
      --card-background: #ffe0b2;
      --button-background: #fb8c00;
      --button-hover: #ef6c00;
      --title-color: #ff6600;
      --progress-bar-bg: #ffe0b2;
      --progress-bar-fill: #fb8c00;
    }
    
     .rasgele-oyun-theme-pink {
      --background-color: #fce4ec;
      --text-color: #880e4f;
      --card-background: #f8bbd0;
      --button-background: #ec407a;
      --button-hover: #d81b60;
      --title-color: #ff6600;
      --progress-bar-bg: #f8bbd0;
      --progress-bar-fill: #ec407a;
    }

/* Game Container - font-weight bold eklenmiş */
.rasgele-kelimeler-game-container {
  background-color: var(--card-background);
  min-height: 100vh;
  color: var(--text-color);
  padding: 20px;
  letter-spacing: 1px;
  font-weight: bold;
}


/* ======================================= */
/* 3) ROTATING-DIAMOND TARZI INTRO KISMI   */
/* ======================================= */

.rasgele-kelimeler-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  animation: fadeInIntro 1s ease-in-out; 
}

/* Gri Kart */
.rasgele-kelimeler-card {
  background-color: #f2f2f2; 
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

/* Kart İç Kısım (daha açık gri) */
.rasgele-kelimeler-card-body {
  background-color: #fcfcfc;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
  letter-spacing: 1px;
  line-height: 1.6;
}

/* Kart Başlığı (Turuncu) */
.rasgele-kelimeler-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ff6600; 
  letter-spacing: 1px;
}

/* Kart Açıklama Metni */
.rasgele-kelimeler-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #555; 
}

/* Talimat Başlık & Liste */
.rasgele-kelimeler-instructions-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #ff6600;
  font-weight: bold;
}

.rasgele-kelimeler-instructions-list {
  list-style: none;
  padding: 0;
  text-align: left;
  max-width: 600px;
  margin: 0 auto 30px auto;
}

.rasgele-kelimeler-instructions-list li {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #333;
}

.rasgele-kelimeler-button {
  background-color: #ff6600;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 3px 8px rgba(255,102,0,0.3);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.rasgele-kelimeler-button:hover {
  background-color: #e65c00;
  box-shadow: 0 3px 12px rgba(255,102,0,0.5);
}

/* Fade-in Animasyonu (Intro için) */
@keyframes fadeInIntro {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.rasgele-kelimeler-intro-container {
  background-color: var(--card-background);
  min-height: 100vh;
  color: var(--text-color);
  padding: 20px;
  letter-spacing: 1px;
}


/* Oyun Ekranı */
.rasgele-kelimeler-gameplay {
  padding: 20px;
  letter-spacing: 1px;
  line-height: 1.6;
}

/* Butonlar (Oyun içinde) */
.kayan-yazilar-button {
  background-color: var(--button-background);
  color: var(--text-color);
  border: none;
  padding: 12px 25px;
  font-size: inherit;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.kayan-yazilar-button:hover {
  background-color: var(--button-hover);
  box-shadow: 0 0 10px var(--button-hover);
}

/* Kontrol Elemanları */
.rasgele-kelimeler-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  letter-spacing: 1px;
  line-height: 1.6;
}

/* Oyun Görüntü Alanı */
.rasgele-kelimeler-game-display {
  background-color: var(--card-background );
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
  border: 2px solid var(--border-color);
  box-shadow: 0 0 10px var(--border-color);
  padding: 40px;
  text-align: center;
  font-size: inherit;
  line-height: 1.5;
  position: relative;
  min-height: 600px;
  letter-spacing: 1px;
}

.rasgele-kelimeler-content {
  width: 100%;
  height: 100%;
  position: relative;
  letter-spacing: 1px;
  line-height: 1.6;
}

/* Mesaj Gösterimi */
.rasgele-kelimeler-message {
  font-size: inherit;
  margin-top: 20px;
  text-align: center;
  color: var(--text-color);

  letter-spacing: 1px; 
  line-height: 1.6;
}

/* Progress Bar */
.rasgele-kelimeler-progress-bar-container {
  width: 80%;
  max-width: 1200px;
  background-color: var(--progress-bar-background);
  border-radius: 10px;   
  overflow: hidden;
  margin: 20px auto;
  border: 2px solid var(--border-color);
  box-shadow: 0 0 10px var(--border-color);
  height: 30px;  
  letter-spacing: 1px;  
}

.rasgele-kelimeler-progress-bar-fill {
  height: 100%;
  background-color: var(--progress-bar-fill);
  width: 0%;
  transition: width 0.5s ease;
  letter-spacing: 1px;
}



/* Ana Kontrol Paneli */
.rasgele-kelimeler-game-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  letter-spacing: 1px;
  line-height: 1.6;
}

.rasgele-kelimeler-game-controls > div {
  margin: 10px;
  letter-spacing: 1px;
}

.rasgele-kelimeler-game-controls label {
  font-size: inherit;
  font-weight: bold;
  color: var(--border-color);
  display: block;
  margin-bottom: 5px;
  letter-spacing: 1px; 
}

.rasgele-kelimeler-game-controls select,
.rasgele-kelimeler-game-controls input[type='range'] {
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--card-background);
  color: var(--text-color);
  border: 2px solid var(--border-color);
  transition: box-shadow 0.3s ease;
  letter-spacing: 1px;
}
.rasgele-kelimeler-game-controls select:hover, 
.rasgele-kelimeler-game-controls select:focus,
.rasgele-kelimeler-game-controls input[type='range']:hover,
.rasgele-kelimeler-game-controls input[type='range']:focus {
  box-shadow: 0 0 10px var(--border-color);
  outline: none;
}

/* Responsive Ayarlar */
@media (max-width: 1024px) {
  .rasgele-kelimeler-title {
    font-size: 2.5rem;
  }
  .rasgele-kelimeler-game-display {
    font-size: 1.3rem;
  }


.rasgele-kelimeler-game-container {
  background-color: var(--background-color);
  min-height: 100vh;
  color: var(--text-color);
  padding: 20px;
  letter-spacing: 1px;
}
}

@media (max-width: 600px) {
  .rasgele-kelimeler-title {
    font-size: 2rem;
  }
  .rasgele-kelimeler-game-display {
    font-size: 1.1rem;
  }
  .rasgele-kelimeler-game-controls {
    flex-direction: column;
    align-items: center;
  }
  .rasgele-kelimeler-game-controls > div {
    width: 100%;
    margin-bottom: 15px;
  }
}
