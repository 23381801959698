@media print {
  .no-print {
    display: none !important;
  }
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  canvas,
  svg {
    display: block !important;
    width: 100% !important;
    height: auto !important;
    overflow: visible !important;
  }

  @page {
    size: auto;
    margin: 20mm;
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  /* EKLENEN KISIM: Print sırasında sayfanın tam olarak yazdırılması ve içeriklerin bölünmemesi için */
  .print-container {
    page-break-inside: avoid;
    break-inside: avoid-page;
  }
}

.game-container-bg{
    background:
    linear-gradient(to bottom right, #696980, #bcd7f5),
    repeating-linear-gradient(
      45deg,    
      transparent 10px,
      transparent 20px
    );
  background-repeat: no-repeat, repeat;
  background-size: 100% 100%, 20px 20px;
  background-blend-mode: multiply;

}

@media (min-width: 1024px) and (max-width: 1440px) {
  .game-container-bg {
    width: 100%;
    min-height: 140vh;
    background-image:
      linear-gradient(to bottom right, #696980, #bcd7f5),
      repeating-linear-gradient(45deg, transparent 10px, transparent 20px);
    background-repeat: no-repeat, repeat;
    background-size: cover, 20px 20px; 
    background-attachment: fixed, fixed;
    background-blend-mode: multiply;
  }
}

 
.bg-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

/* Arka plan resmi bulanık */
.bg-main::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../../../public/images/egzersiz_bg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(0.7) blur(5px);
  z-index: -1;
}

.main-container {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 40px;
}

.theme-default {
  --primary-color: #11318f;  
  --secondary-color: #B89D69; 
  --button-text-color: #FFFFFF; 
  --progress-bar-bg: #333; 
  --oyun-alani: #f8f8f8;
  --background-color: #f5f5f5;
  --text-color: #11318f;
  --card-background: #ffffff;
  --button-background: #007bff;
  --button-hover: #0056b3; 
  --progress-bar-fill: #007bff;
  --accent-color: #0d6efd; 
  --title-color: var(--primary-color);
  --border-color: var(--primary-color);
  --progress-bar-fill: var(--primary-color);

}

.theme-tomer {
  --primary-color: #274989;  
  --secondary-color: #B89D69; 
  --accent-color: #FFDD57;  
  --background-color: #F9FAFB;  
  --card-background: #FFFFFF;   
  --text-color: var(--primary-color);  
  --button-background: var(--primary-color);
  --button-hover: #3B3498; 
  --button-text-color: #FFFFFF;
  --title-color: var(--secondary-color);
  --border-color: var(--primary-color);
  --progress-bar-bg: #333;
  --progress-bar-fill: var(--secondary-color);
  --oyun-alani: #f8f8f8;
}

.theme-default,
.theme-default * {
  font-family: Arial, sans-serif !important;
}


.title {
  color:  var(--secondary-color, #211C84); 
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  word-wrap: break-word; 
  font-weight: 600;
}


/* INTRO */
.intro-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.intro-title {
  color: #211C84; 
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  word-wrap: break-word; 
}

.intro-description {
  color: #333333; 
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.5;
  max-width: 600px;
  word-wrap: break-word; 
}


.intro-instructions-box {
  background-color: #ffffff; 
  padding: 1.5rem;
  border-radius: 6px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); 
}

.intro-instructions-title {
  color: #211C84; 
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.intro-instructions-list {
  list-style: none; 
  padding: 0;
  margin-bottom: 1.5rem;
}

.intro-instructions-list li {
  color: #333333; 
  margin-bottom: 0.8rem;
  line-height: 1.4;
}

.intro-instructions-list strong {
  color: #211C84; 
}

.intro-button {
  background-color: #211C84; 
  color: #ffffff;  
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.intro-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 480px) {
  .intro-container {
    padding: 1rem; 
    border-radius: 0; 
    box-shadow: none; 
  }

  .intro-title {
    font-size: 1.6rem;
    margin-bottom: 0.75rem;
  }

  .intro-description {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .intro-instructions-title {
    font-size: 1.1rem;
  }

  .intro-button {
    width: 100%;
    font-size: 0.9rem;
    padding: 0.8rem;
  }
}

@media (min-width: 992px) {
  .intro-title {
    font-size: 2.2rem;
  }

  .intro-description {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .intro-container {
    max-width: 900px;  
    margin: 0 auto;
    padding: 2rem;
  }

  .intro-title {
    font-size: 2rem;  
    margin-bottom: 1rem;
  }

  .intro-description {
    font-size: 1rem;   
    margin-bottom: 2rem;
  }

  .intro-instructions-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .intro-instructions-list li {
    font-size: 1rem;
  }

  .intro-button {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
}


/* HEADER-BUTTONS */
.header-buttons {
 
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0; 
  padding: 0;
}

.header-button {
  background-color: #274989 !important;
  color: #ffffff !important;
  border: none;
  padding: 10px 24px;
  font-size: 1rem !important;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 700;
  margin: 0;
}

.header-button:hover {
  background-color: #3e38b3 !important;
}

/* 768px ile 1440px arası ekranlar için */



@media (min-width: 768px) and (max-width: 1450px) {
  .header-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Dilersen gap'i de azaltabilir veya sıfırlayabilirsin: */
    gap: 10px; 
    margin: 10px 0;
  }
}




.timer {
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 1px;
  line-height: 1.6;
}

.timer span {
  font-size: 2.2rem;
  letter-spacing: 1px;
  font-weight: 700;
}

/******************************************************
  HIZ KONTROLÜ 
******************************************************/
.speed-control-container {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: var(--card-background,  #11318f);  
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
  justify-content: center;
  margin: 0 auto 0;  
}


.speed-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: var(--button-background, #f9f9f9);
}

.speed-display {

  display: flex;
  align-items: center;
  gap: 10px;
}

.speed-button {
  background-color: var(--button-background);
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: var(--button-background) 0.3s;
}

.speed-button:hover {
  background-color: var(--button-hover);
}

.speed-value {
  font-size: 1.2rem;
  font-weight: bold;
  min-width: 40px;
  text-align: center;
  background: #111;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}

.speed-instructions {
  font-size: 0.9rem;
  color: #ccc;
  text-align: center;
}


/* GAME BUTTONS */
.game-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;          
  margin: 5px 0;    
}

.game-button { 
  background-color: var(--button-background, #11318f);
  color: #ffffff !important;
  border: none;      
  font-size: 1rem !important;    
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 700;
  padding: 12px 25px;
  border-radius: 5px;
  margin-top: 10px;
}

.game-button:hover {
  background-color: var(--button-hover, #0056b3);
}

.game-select { 
  background-color:var(--button-background, #11318f);
  color: #e6dddd;
  border: none;
  font-weight: 700;
  padding: 12px 25px;
  border-radius: 5px;
  margin-top: 10px;
}


.game-select-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #11318f;
}

/******************************************************
  SETTINGS MODAL STYLES
******************************************************/
.settings-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem; 
  z-index: 9999;
}

/* Modal içerik: modern, gölgeli ve yumuşak animasyonlu */
.settings-modal-content {
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  color: #333;
  padding: 2rem;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-out;
}

/* Fade in animasyonu */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Üst sağdaki kapatma butonu */
.settings-modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  color: #666;
  transition: color 0.2s ease;
}

.settings-modal-close-button:hover {
  color: #ff6600;
}

/* Modal başlık */
.settings-modal-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
}

/* Form grupları */
.settings-form-group {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

/* Etiketler */
.settings-form-label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #444;
}

/* Seçim kutuları */
.settings-form-select {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fafafa;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease;
}

.settings-form-select:focus {
  border-color: #ff6600;
}

/* Alt kısımda bulunan kapatma butonu */
.settings-modal-bottom-close-button {
  display: inline-block;
  background-color: #ff6600;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.settings-modal-bottom-close-button:hover {
  background-color: #e65c00;
}

/* 750px üstü ekranlarda da genişlik yüzdeli ama max 700px'ten büyük olmasın */
@media (min-width: 750px) and (max-width: 1580px) {
  .settings-modal-content {
    width: 90%;
    max-width: 700px;
  }
}

/* 1580px üstünde biraz daha genişletebilirsiniz */
@media (min-width: 1580px) {
  .settings-modal-content {
    width: 90%;
    max-width: 800px;
  }
}
