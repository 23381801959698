/* Genel Stil ve Arka Plan */
.okuma-seviye-testi-container {

  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  color: #f0f0f0;
  padding: 20px;
}


/* Başlık Bölümü */
.okuma-seviye-testi-header {
  text-align: center;
  margin-bottom: 20px;
}

.okuma-seviye-testi-header h1 {
  font-size: 3rem;
  color: #ff8c00; 
  font-weight: 700;
}

/* Talimatlar */
.okuma-seviye-testi-instructions {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}



.okuma-seviye-testi-instructions p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #f0f0f0; 
}

/* Butonlar */
.okuma-seviye-testi-button {
  background-color: #ff8c00; 
  color: #1a1a1a; 
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.okuma-seviye-testi-button:hover {
  background-color: #e67e22; 
  transform: translateY(-2px);
}

.okuma-seviye-testi-button:active {
  transform: translateY(0);
}

/* Sınav Alanı */
.okuma-seviye-testi-game-area {
  text-align: center;
}

/* Sınav Alanı */
.okuma-seviye-testi-exam-area,
.okuma-seviye-testi-game-area {
  text-align: center;
}

/* Zamanlayıcı */
.okuma-seviye-testi-timer {
  display: inline-block;
  background-color: #ff8c00; 
  color: #1a1a1a; 
  padding: 10px 20px;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}

/* Paragraf Bölümü */
.okuma-seviye-testi-paragraph {
  max-width: 1000px; 
  margin: 0 auto 20px;
  background-color: #fff; 
  padding: 40px 30px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  color: #333; 
}


.okuma-seviye-testi-paragraph::before {
  content: '“';
  font-size: 100px;
  position: absolute;
  top: -20px;
  left: 20px;
  color: rgba(200, 200, 200, 0.5);
  font-family: Georgia, serif;
}

.okuma-seviye-testi-paragraph p {
  font-size: 24px;
  line-height: 1.8;
  font-weight: 300;
  color: #333; /* Yazı rengi siyah */
  text-align: justify;
  text-indent: 50px;
}

.okuma-seviye-testi-paragraph::after {
  content: '”';
  font-size: 100px;
  position: absolute;
  bottom: -40px;
  right: 20px;
  color: rgba(200, 200, 200, 0.5);
  font-family: Georgia, serif;
}

/* Scrollbar Stili */
.okuma-seviye-testi-paragraph::-webkit-scrollbar {
  width: 10px;
}

.okuma-seviye-testi-paragraph::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.okuma-seviye-testi-paragraph::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

/* Kontroller */
.okuma-seviye-testi-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

/* Modal Stilleri */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  background: #1a1a1a; /* Koyu arka plan */
  color: #f0f0f0; /* Açık yazı rengi */
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: fadeIn 0.3s ease-out;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #f0f0f0;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #e74c3c;
}

/* Responsive Tasarım */
@media (max-width: 768px) {
  .okuma-seviye-testi-paragraph {
    max-height: 300px;
    padding: 30px 20px;
  }

  .okuma-seviye-testi-paragraph p {
    font-size: 20px;
  }

  .okuma-seviye-testi-header h1 {
    font-size: 2.5rem;
  }



  .okuma-seviye-testi-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

/* Header */
.okuma-seviye-testi-header {
  text-align: center;
  margin-bottom: 20px;
}

.okuma-seviye-testi-header h1 {
  font-size: 3rem;
  color: #ff8c00; /* Turuncu renk */
  font-weight: 700;
  margin: 0;
}

.header-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.header-button {
  background-color: transparent;
  color: #ff8c00; /* Turuncu renk */
  border: none;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header-button:hover {
  color: #e67e22; /* Daha koyu turuncu */
}

.header-button svg {
  margin-right: 5px;
}


.unrealistic-warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.unrealistic-warning-content {
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  max-width: 400px;
  width: 80%;
  text-align: center;
  box-shadow: 0px 10px 25px rgba(0,0,0,0.2);
  animation: scaleIn 0.3s ease forwards;
}

.unrealistic-warning-content h3 {
  font-size: 1.5rem;
  color: #ff4d4d; /* dikkat çeken bir kırmızı ton */
  margin-bottom: 20px;
}

.unrealistic-warning-content p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 30px;
  line-height: 1.5;
}

.unrealistic-warning-button {
  background: #ff4d4d;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.unrealistic-warning-button:hover {
  background: #d93c3c;
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Fade-in animasyonu */
.fade-in {
  animation: fadeIn 0.4s ease forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


/* Zorluk Seçimi (Select) Alanı Ortalama ve Stil */
.select-difficulty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50px;
}



.select-difficulty-container select {
  background-color: #1a1a1a;
  color: #f0f0f0;
  border: 2px solid #ff8c00;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  cursor: pointer;
}

.select-difficulty-container select:focus {
  outline: none;
  box-shadow: 0 0 5px #ff8c00;
}

