.zigzag-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: #fafafa;
  overflow: hidden;
  padding: 1rem;
}

.zigzag-card {
  background-color: #fafafa;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  box-shadow: 0 0 20px rgba(0,0,0,0.7);
}

.zigzag-card-body {
  padding: 40px;
  text-align: center;
}

/* STATUS ALANI (dot-tracking benzeri) */
.zigzag-status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  width: 100%;
}

.zigzag-status-left,
.zigzag-status-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* 
  CANVAS KONTEYNER 

*/
.zigzag-canvas-container {
  width: min(80vmin, 80vmax);
  height: min(80vmin, 80vmax);
  position: relative;
  margin: 0 auto;
  border: 1px solid #11318f;
  border-radius: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

/* Canvas kendisini kapsayıcıya göre ölçeklesin */
.zigzag-game-canvas {
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
}

/* KONTROL SATIRI */
.zigzag-control-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.zigzag-game-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #ffffff;
  border: 1px solid #11318f;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
}

.zigzag-game-controls button {
  background-color: #11318f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 5px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 3px 8px rgba(255,102,0,0.3);
}
.zigzag-game-controls button:hover {
  background-color: #e65c00;
  box-shadow: 0 3px 12px rgba(255,102,0,0.5);
}

/* ZORLUK SELECT */
.zigzag-select {
  min-width: 150px;
  margin-left: 10px;
}

.zigzag-speed-control-container {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #ffffff;
  border: 1px solid #11318f;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
}

.zigzag-speed-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #11318f;
}

.zigzag-speed-display {
  display: flex;
  align-items: center;
  gap: 10px;
}

.zigzag-speed-button {
  background-color: #11318f;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.zigzag-speed-button:hover {
  background-color: #e65c00;
}

.zigzag-speed-value {
  font-size: 1.2rem;
  font-weight: bold;
  min-width: 40px;
  text-align: center;
  background: #f9f9f9;
  padding: 5px 10px;
  border-radius: 5px;
  color: #333;
  border: 1px solid #ccc;
}

.zigzag-speed-instructions {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
}

/* TIMER */
.zigzag-timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zigzag-timer {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.zigzag-timer-progress-bar {
  width: 100%;
  max-width: 200px;
  background-color: #eee;
  height: 10px;
  border-radius: 5px;
}

.zigzag-timer-progress {
  height: 100%;
  border-radius: 5px;
}

/* SKOR */
.zigzag-score-container {
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 5px;
}

.zigzag-score-icon {
  color: #ff6600;
  font-size: 1.2rem;
}

.zigzag-score-text {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #333;
}

/* MODAL */
.zigzag-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: zigzagModalFadeIn 0.3s ease-out;
}

@keyframes zigzagModalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.zigzag-modal-content {
  background: #ffffff;
  color: #333333;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  max-width: 450px;
  width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: zigzagModalFadeIn 0.3s ease-out;
}

.zigzag-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #333333;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
}
.zigzag-close-button:hover {
  color: #e74c3c;
}

.zigzag-modal-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: #ff6347;
}

.zigzag-modal-score,
.zigzag-modal-message {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}

.zigzag-button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.zigzag-btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.zigzag-btn-primary {
  background-color: #11318f;
  color: #ffffff;
}
.zigzag-btn-primary:hover {
  background-color: #ff4500;
}

.zigzag-btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
}
.zigzag-btn-secondary:hover {
  background-color: #5a6268;
}

.zigzag-btn-outline {
  background-color: transparent;
  border: 2px solid #11318f;
  color: #ff6347;
}
.zigzag-btn-outline:hover {
  background-color: #ff6347;
  color: #ffffff;
}


/* 1024px üstü ekranlar */
@media (min-width: 1024px) {
  .zigzag-canvas-container {
    width: min(60vmin, 1000px);
    height: min(60vmin, 1000px);
  }
  .zigzag-status {
    gap: 3rem;
  }
  .zigzag-control-row {
    gap: 2rem;
  }
}

/* 768px altı ekranlar */
@media (max-width: 768px) {
  .zigzag-status,
  .zigzag-control-row {
    flex-direction: column;
  }
  .zigzag-game-controls, 
  .zigzag-speed-control-container {
    width: 90%;
    justify-content: center;
  }
  .zigzag-game-controls button {
    width: 100%;
    margin: 5px 0;
  }
}

/* ZORLUK SELECT (GÜNCELLENMİŞ) */
.zigzag-select {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #11318f;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 0.9rem;
  outline: none;
  cursor: pointer;
  transition: border 0.3s, box-shadow 0.3s;
  appearance: none;
  margin-left: 10px;
}

.zigzag-select:hover {
  box-shadow: 0 3px 8px rgba(255,102,0,0.3);
}

.zigzag-select:focus {
  border-color: #11318f; 
  box-shadow: 0 3px 8px rgba(255,102,0,0.5);
}

/* OPSİYONLARI DA İSTEDİĞİNİZ GİBİ RENKLENDİREBİLİRSİNİZ */
.zigzag-select option {
  background-color: #ffffff;
  color: #333;
}
