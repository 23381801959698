.hangi-harfi-gordun-container {
  min-height: 100vh;
  color: #333;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}

/* GİRİŞ EKRANI */
.hangi-harfi-gordun-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

/* KART TASARIMI */
.hangi-harfi-gordun-card {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

.hangi-harfi-gordun-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px; 
  padding: 20px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
  width: 100%;
}

/* BAŞLIK VE AÇIKLAMA */
.hangi-harfi-gordun-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ff6600;
}

.hangi-harfi-gordun-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.5;
  color: #555;
}

/* TALİMATLAR */
.hangi-harfi-gordun-instructions {
  text-align: left;
  margin-bottom: 30px;
  width: 100%;
}

.hangi-harfi-gordun-instructions h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #ff6600;
  text-align: center;
}

.hangi-harfi-gordun-instructions ul {
  list-style: none;          
  padding: 0;
  counter-reset: my-counter;   
  margin: 0 auto 30px auto;
}

.hangi-harfi-gordun-instructions li {
  counter-increment: my-counter; 
  position: relative;
  
  padding-left: 25px;  
  margin-left: 45px ; 
 
}

.hangi-harfi-gordun-instructions li::before {
  content: counter(my-counter) ". "; 
  position: absolute;
  left: 0;
  color: #161312;         
  font-size: 20px;
  line-height: 1;
}

/* BUTON GENEL STİL */
.hangi-harfi-gordun-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.hangi-harfi-gordun-button:hover {
  background-color: #e65c00;
}

/* OYUN ALANI */
.hangi-harfi-gordun-gameplay {
  padding: 50px 20px;
}

/* 
  BURADA ZORLUK ve HIZ AYARINI YAN YANA ALIYORUZ 
  -----------------------------------------------
*/
.hangi-harfi-gordun-settings {

  display: flex;
  flex-wrap: wrap;        
  align-items: center;
  justify-content: center;
  gap: 15px;               /* Aradaki boşluk */
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
}

/* Her bir alan: Zorluk, Süre vb. */
.hangi-harfi-gordun-setting {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  min-width: 180px;
}

.hangi-harfi-gordun-setting label {
  margin-bottom: 5px;
  font-size: 1.1rem;

  font-weight: 600;
}

.hangi-harfi-gordun-setting select,
.hangi-harfi-gordun-setting input[type='range'] {
  width: 100%;
  font-size: 1.1rem;
  padding: 8px;
  outline: none;
}

.hangi-harfi-gordun-setting select:focus,
.hangi-harfi-gordun-setting input[type='range']:focus {
  border-color: #e65c00;
}

.hangi-harfi-gordun-setting span {
  font-size: 1rem;
  color: #555;
  margin-top: 5px;
}

/* Range stil */
.hangi-harfi-gordun-setting input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  opacity: 0.8;
  transition: opacity 0.2s;
}
.hangi-harfi-gordun-setting input[type='range']:hover {
  opacity: 1;
}
.hangi-harfi-gordun-setting input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ff6600;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
}
.hangi-harfi-gordun-setting input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ff6600;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
}

/* 
  BUTONLAR DA YANINDA DURUYOR 
  (isterseniz ayrı div olarak altına da koyabilirsiniz)
*/
.hangi-harfi-gordun-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

/* STATUS - Kalan Süre, Skor */
.hangi-harfi-gordun-status {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #0056b3;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
  border-radius: 6px;
  padding: 10px;
  color: #333;
}

.hangi-harfi-gordun-status p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

/* CANVAS ve INPUT */
.hangi-harfi-gordun-canvas-container {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto 20px auto;
}

.hangi-harfi-gordun-canvas {
  width: 100%;
  height: 100%;
  border: 2px solid #ff6600;
  border-radius: 10px;
  background-color: #fff;
}

.hangi-harfi-gordun-input-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.hangi-harfi-gordun-input-form input {
  width: 80%;
  max-width: 300px;
  padding: 15px;
  font-size: 1.2rem;
  margin-bottom: 15px;
  border: 2px solid #ff6600;
  border-radius: 5px;
  background-color: #fafafa;
  color: #333;
  text-transform: uppercase;
}
.hangi-harfi-gordun-input-form input::placeholder {
  color: #999;
}
.hangi-harfi-gordun-input-form button {
  width: 50%;
  max-width: 150px;
  background-color: #0056b3;
  color: #fff;
  font-weight: 600;
}

/* ANİMASYON */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hangi-harfi-gordun-card {
  animation: fadeIn 1s ease-in-out;
}

/* RESPONSIVE */
@media (max-width: 768px) {
  .hangi-harfi-gordun-card-body {
    padding: 20px;
  }

  .hangi-harfi-gordun-title {
    font-size: 2rem;
  }

  /* Settings alanı dar ekranda alt satıra kayacak */
  .hangi-harfi-gordun-setting {
    min-width: auto;
    width: 100%;
    align-items: flex-start;
  }

  .hangi-harfi-gordun-status {
    flex-direction: column;
    gap: 5px;
  }

  .hangi-harfi-gordun-canvas-container {
    width: 100%;
    height: auto;
  }

  .hangi-harfi-gordun-canvas {
    width: 100%;
    height: 300px; /* Daha ufak bir yükseklik */
  }

  .hangi-harfi-gordun-input-form input {
    width: 100%;
  }
  .hangi-harfi-gordun-input-form button {
    width: 80%;
  }
}

