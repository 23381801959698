/* Koyu Tema */
.theme-dark {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --card-background: #2c2c2c;
  --button-background: #11318f;
  --button-hover: #e65c00;
  --progress-bar-background: #333;
  --progress-bar-fill: #ffcc00;
}

/* Açık Tema */
.theme-light {
  --background-color: #f5f5f5;
  --text-color: #1a1a1a;
  --card-background: #ffffff;
  --button-background: #007bff;
  --button-hover: #0056b3;
  --progress-bar-background: #ccc;
  --progress-bar-fill: #007bff;
}

/* Mavi Tema */
.theme-blue {
  --background-color: #e3f2fd;
  --text-color: #0d47a1;
  --card-background: #bbdefb;
  --button-background: #1e88e5;
  --button-hover: #1565c0;
  --progress-bar-background: #90caf9;
  --progress-bar-fill: #1e88e5;
}

/* Yeşil Tema */
.theme-green {
  --background-color: #e8f5e9;
  --text-color: #1b5e20;
  --card-background: #c8e6c9;
  --button-background: #43a047;
  --button-hover: #2e7d32;
  --progress-bar-background: #a5d6a7;
  --progress-bar-fill: #43a047;
}

/* Kırmızı Tema */
.theme-red {
  --background-color: #fff5f5;
  --text-color: #b71c1c;
  --card-background: #ffcdd2;
  --button-background: #f44336;
  --button-hover: #d32f2f;
  --title-color: #ff6600;
}

/* Mor Tema */
.theme-purple {
  --background-color: #f3e5f5;
  --text-color: #4a148c;
  --card-background: #ce93d8;
  --button-background: #9c27b0;
  --button-hover: #7b1fa2;
  --title-color: #ff6600;
}

/* Turuncu Tema */
.theme-orange {
  --background-color: #fff3e0;
  --text-color: #e65100;
  --card-background: #ffe0b2;
  --button-background: #fb8c00;
  --button-hover: #ef6c00;
  --title-color: #ff6600;
}

/* Pembe Tema */
.theme-pink {
  --background-color: #fce4ec;
  --text-color: #880e4f;
  --card-background: #f8bbd0;
  --button-background: #ec407a;
  --button-hover: #d81b60;
  --title-color: #ff6600;
}

.kelimebulmaca-container {
  min-height: 100vh;
  color: #333;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Üst bar: Timer + Kelime bilgisi */
.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.timer-section,
.found-words-section {
  background: #ffffff;
  border: 1px solid #11318f;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  min-width: 200px;
  text-align: center;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
}

.kelimebulmaca-score-container {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  background-color: #fff7eb;
  padding: 10px 20px;
  border-radius: 6px;
  color: #11318f;
  box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
  margin-bottom: 15px;
}

.kelimebulmaca-info {
  font-size: 1.1rem;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #555;
}

/* Oyun Alanı (Grid) */
.game-area {
  width: 100%;
  /* max-width: auto; // Bu satır pratikte kısıtlama yapmaz, kullanmasak da olur */
  /* İsterseniz tamamen kaldırabilirsiniz veya: */
  max-width: 900px; /* Aynı etkiyi sağlar */
  background: #ffffff;
  border: 1px solid #ff6600;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
}


/* 768px - 1480px arası için responsive ayarlar */
@media (min-width: 768px) and (max-width: 1480px) {
  .game-area {
    max-width: 900px; /* Örneğin 900px’e düşür */
    padding: 15px;    /* İhtiyaca göre padding azalt veya arttır */
    margin-top: 15px; /* Üst boşluk ayarı */
  }
}

.kelimebulmaca-puzzle-container {
  width: 100%;
  max-width: 1000px;
  background: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  text-align: center;
  overflow: auto;
}

.kelimebulmaca-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
}

.grid {
  display: inline-block;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #ff6600;
  padding: 10px;
  box-shadow: inset 0 0 3px rgba(0,0,0,0.05);
}

.grid-row {
  display: flex;
}

.grid-cell {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.2rem;
  color: #ff6600;
  background: #fff;
  transition: background 0.2s, box-shadow 0.2s;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.grid-cell:hover {
  background: #ffe6d5;
}

.grid-cell.found {
  background: #ffcf7c;
  color: #000;
  border-color: #ffa500;
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.5);
}


/* Timer */
.kelimebulmaca-timer-container {
  text-align: center;
}

.kelimebulmaca-timer-display {
  font-size: 2.2rem;
  color: #ff6600;
  margin-bottom: 15px;
  font-weight: 700;
}

.kelimebulmaca-timer-progress-bar {
  width: 100%;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ffcc00;
  box-shadow: inset 0 0 3px rgba(0,0,0,0.05);
  height: 25px;
  margin: 0 auto;
}

.kelimebulmaca-timer-progress {
  height: 100%;
  background-color: #2ecc71;
  width: 0%;
  transition: width 0.5s ease;
}

/* Modal */
.kelimebulmaca-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.kelimebulmaca-modal-container {
  background: #ffffff;
  color: #333333;
  padding: 2.5rem;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.25);
  position: relative;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
  border: 1px solid #ff6600;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.kelimebulmaca-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #ff6600;
  font-size: 1.5rem;
  cursor: pointer;
}

.kelimebulmaca-close-button:hover {
  color: #e65c00;
}

.kelimebulmaca-modal-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: #ff6347;
}

.kelimebulmaca-modal-score {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: #444;
}

.kelimebulmaca-modal-button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.kelimebulmaca-modal-button {
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}

.kelimebulmaca-modal-button.primary {
  background-color: #ff6600;
  color: #ffffff;
}

.kelimebulmaca-modal-button.primary:hover {
  background-color: #e65c00;
}

.kelimebulmaca-modal-button.secondary {
  background-color: #6c757d;
  color: #ffffff;
}

.kelimebulmaca-modal-button.secondary:hover {
  background-color: #5a6268;
}

.kelimebulmaca-modal-button.outline {
  background-color: transparent;
  border: 2px solid #ff6600;
  color: #ff6600;
}

.kelimebulmaca-modal-button.outline:hover {
  background-color: #ff6600;
  color: #ffffff;
}

/* ========== RESPONSIVE ========== */

/* Tablet: max-width 1024px */
@media (max-width: 1024px) {
  .top-bar {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .found-words-section, .timer-section {
    width: 90%;
    margin-left: 0;
  }
  .grid-cell {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }
}

/* Büyük telefon - küçük tablet: max-width 768px */
@media (max-width: 768px) {
  .grid-cell {
    width: 30px;
    height: 30px;
    font-size: 0.9rem;
  }

  .bottom-buttons {
    flex-direction: column;
    gap: 10px;
    width: 90%;
  }
  .kelimebulmaca-button {
    width: 100%;
    text-align: center;
  }
  .found-words-section, .timer-section {
    width: 100%;
    margin: 0;
  }
}

/* Küçük ekran: max-width 480px */
@media (max-width: 480px) {
  .kelimebulmaca-timer-display {
    font-size: 1.8rem;
  }
  .kelimebulmaca-info {
    font-size: 1rem;
  }
  .kelimebulmaca-score-container {
    font-size: 1rem;
  }
  .grid-cell {
    width: 28px;
    height: 28px;
    font-size: 0.85rem;
  }
  .kelimebulmaca-modal-title {
    font-size: 1.6rem;
  }
  .kelimebulmaca-modal-score {
    font-size: 1.2rem;
  }
}

/* Hard modda landscape */
@media (max-width: 1024px) {
  body.hard-mode-landscape .game-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
  }
  body.hard-mode-landscape .kelimebulmaca-puzzle-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
  body.hard-mode-landscape .top-bar {
    flex-direction: column;
    align-items: center;
  }
  body.hard-mode-landscape .grid-cell {
    width: 25px;
    height: 25px;
    font-size: 0.8rem;
  }
}

