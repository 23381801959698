.sozlesme-container {
  max-width: 800px; 
  margin: 0 auto;
  padding: 20px; 
}

.section {
  margin-bottom: 40px; 
}

.title {
  text-align: center; 
  font-size: 32px;
  margin-bottom: 40px;
}

.section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.section p,
.section ul {
  font-size: 16px;
  line-height: 1.6;
}

.section ul {
  list-style-type: disc;
  margin-left: 20px;
}

.taraflar {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.taraf {
  flex: 1;
  min-width: 300px;
}

.taraf h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.taraf ul {
  list-style-type: none;
  padding: 0;
}

.taraf ul li {
  margin-bottom: 5px;
}

a {
  color: #007BFF; 
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

