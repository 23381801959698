html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  min-height: 100vh;
}
*, *::before, *::after {
  box-sizing: inherit;
}

.theme-dark {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --card-background: #2c2c2c;
  --button-background: #ff6600;
  --button-hover: #e65c00;
  --progress-bar-background: #333;
  --progress-bar-fill: #ffcc00;
}

.theme-blue {
  --background-color: #e3f2fd;
  --text-color: #0d47a1;
  --card-background: #bbdefb;
  --button-background: #1e88e5;
  --button-hover: #1565c0;
  --progress-bar-background: #90caf9;
  --progress-bar-fill: #1e88e5;
}

.theme-green {
  --background-color: #e8f5e9;
  --text-color: #1b5e20;
  --card-background: #c8e6c9;
  --button-background: #43a047;
  --button-hover: #2e7d32;
  --progress-bar-background: #a5d6a7;
  --progress-bar-fill: #43a047;
}

.theme-red {
  --background-color: #fff5f5;
  --text-color: #b71c1c;
  --card-background: #ffcdd2;
  --button-background: #f44336;
  --button-hover: #d32f2f;
  --title-color: #ff6600;
}

/* Mor Tema */
.theme-purple {
  --background-color: #f3e5f5;
  --text-color: #4a148c;
  --card-background: #ce93d8;
  --button-background: #9c27b0;
  --button-hover: #7b1fa2;
  --title-color: #ff6600;
}

.theme-orange {
  --background-color: #fff3e0;
  --text-color: #e65100;
  --card-background: #ffe0b2;
  --button-background: #fb8c00;
  --button-hover: #ef6c00;
  --title-color: #ff6600;
}

.theme-pink {
  --background-color: #fce4ec;
  --text-color: #880e4f;
  --card-background: #f8bbd0;
  --button-background: #ec407a;
  --button-hover: #d81b60;
  --title-color: #ff6600;
}


/******************************************************
  GENEL YAPI
******************************************************/
.kayan-yazilar-game-container {
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  box-sizing: border-box;
}



/******************************************************
  FADE-IN ANİMASYONU
******************************************************/
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/******************************************************
  GAME DISPLAY
******************************************************/
.kayan-yazilar-game-display {
  background-color:  var(--card-background,  rgba(255, 102, 0, 0.9));  
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  line-height: 1.5;
  font-size: var(--font-size, 1.5rem);
}

/******************************************************
  PROGRESS BAR
******************************************************/
.kayan-yazilar-progress-bar-container {
  width: 80%;
  max-width: 1200px;
  background-color: var(--progress-bar-background);
  border-radius: 10px;   
  overflow: hidden;
  margin: 20px auto;
  border: 2px solid var(--border-color);
  box-shadow: 0 0 10px var(--border-color);
  height: 30px;  
  letter-spacing: 1px;  
}

.kayan-yazilar-progress-bar-fill {
  height: 100%;
  background-color: var(--progress-bar-fill);
  width: 0%;
  transition: width 0.5s ease;
  letter-spacing: 1px;
}



/******************************************************
  MESAJ
******************************************************/
.kayan-yazilar-message {
  font-size: 1.5rem;
  margin-top: 20px;
  text-align: center;
  font-weight: 700;
  color: var(--text-color);
}

/******************************************************
  GAME SETUP (Sola Butonlar, Sağa Hız Ayarı)
******************************************************/
.game-setup-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;         
}

.game-setup-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.game-setup-right {
  display: flex;
  align-items: center;
}

/******************************************************
  KONTROLLER (ESAS ÖNEMLİ KISIM) 
******************************************************/
.kayan-yazilar-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/******************************************************
  RESPONSIVE MEDIA QUERIES
******************************************************/

/* 768px Altı (Mobil) */
@media (max-width: 768px) {

  .kayan-yazilar-card-body {
    padding: 20px;
  }
  .kayan-yazilar-title {
    font-size: 2rem;
  }
  .kayan-yazilar-button {
    width: 90%;
    max-width: 300px;
  }
}

/* 480px ve Daha Altı (Daha Küçük Mobil) */
@media (max-width: 480px) {
  .kayan-yazilar-title {
    font-size: 1.8rem;
  }


}

/* 1024px ve ÜSTÜ (Büyük Ekranlar) */
@media (min-width: 1024px) {


  .kayan-yazilar-game-container {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .kayan-yazilar-header-sticky {
    position: sticky;
    top: 0;
    z-index: 9999;
    width: 100%;
    background-color: var(--background-color);
    margin-bottom: 10px;
  }


  .kayan-yazilar-card {
    max-width: 1000px;
  }

  .kayan-yazilar-card-body {
    padding: 50px;
  }



  .kayan-yazilar-button {
    font-size: 1.2rem;
    padding: 15px 40px;
    margin: 10px;
  }

  .kayan-yazilar-gameplay {
    flex: 1;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .kayan-yazilar-game-display {
    max-height: 500px;
    font-size: 1.8rem;
  }

  .kayan-yazilar-message {
    font-size: 2rem;
  }
}
