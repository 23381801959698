/******************************************************
  TEMALAR
******************************************************/

/* Kırmızı Tema */
.theme-red {
  --background-color: #fff5f5;
  --text-color: #b71c1c;
  --card-background: #ffcdd2;
  --button-background: #f44336;
  --button-hover: #d32f2f;
  --title-color: #ff6600;
  --progress-bar-bg: #ffcdd2;
  --progress-bar-fill: #f44336;
}

/* Mor Tema */
.theme-purple {
  --background-color: #f3e5f5;
  --text-color: #4a148c;
  --card-background: #ce93d8;
  --button-background: #9c27b0;
  --button-hover: #7b1fa2;
  --title-color: #ff6600;
  --progress-bar-bg: #ce93d8;
  --progress-bar-fill: #9c27b0;
}

/* Turuncu Tema */
.theme-orange {
  --background-color: #fff3e0;
  --text-color: #e65100;
  --card-background: #ffe0b2;
  --button-background: #fb8c00;
  --button-hover: #ef6c00;
  --title-color: #ff6600;
  --progress-bar-bg: #ffe0b2;
  --progress-bar-fill: #fb8c00;
}

/* Pembe Tema */
.theme-pink {
  --background-color: #fce4ec;
  --text-color: #880e4f;
  --card-background: #f8bbd0;
  --button-background: #ec407a;
  --button-hover: #d81b60;
  --title-color: #ff6600;
  --progress-bar-bg: #f8bbd0;
  --progress-bar-fill: #ec407a;
}

/* Koyu Tema */
.theme-dark {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --card-background: #2c2c2c;
  --button-background: #ff6600;
  --button-hover: #e65c00;
  --title-color: #ffcc00;
  --progress-bar-bg: #333;
  --progress-bar-fill: #ffcc00;
}

/* Mavi Tema */
.theme-blue {
  --background-color: #e3f2fd;
  --text-color: #0d47a1;
  --card-background: #bbdefb;
  --button-background: #1e88e5;
  --button-hover: #1565c0;
  --title-color: #ff6600;
  --progress-bar-bg: #90caf9;
  --progress-bar-fill: #1e88e5;
}

/* Yeşil Tema */
.theme-green {
  --background-color: #e8f5e9;
  --text-color: #1b5e20;
  --card-background: #c8e6c9;
  --button-background: #43a047;
  --button-hover: #2e7d32;
  --title-color: #ff6600;
  --progress-bar-bg: #a5d6a7;
  --progress-bar-fill: #43a047;
}


/******************************************************
  GENEL YAPI
******************************************************/

.kayan-yazilar-ucer-game-container,
.kayan-yazilar-ucer-intro-container {
  background-color: var(--background-color);
  min-height: 100vh;
  color: var(--text-color);
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/******************************************************
  INTRO KISMI (Ana Ekran)
******************************************************/

.kayan-yazilar-ucer-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  animation: fadeInIntro 1s ease-in-out;
}

.kayan-yazilar-ucer-card {
  background-color: #f2f2f2; 
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.kayan-yazilar-ucer-card-body {
  background-color: #fcfcfc;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
}

.kayan-yazilar-ucer-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ff6600;
}

.kayan-yazilar-ucer-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #555;
}

.kayan-yazilar-ucer-instructions-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #ff6600;
  font-weight: bold;
}

.kayan-yazilar-ucer-instructions-list {
  list-style: none;
  padding: 0;
  max-width: 600px;
  margin: 0 auto 30px auto;
  text-align: left;
}

/* Intro Butonu (Turuncu, hover gölgesi) */
.kayan-yazilar-ucer-button {
  background-color: #ff6600;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 3px 8px rgba(255,102,0,0.3);
}

.kayan-yazilar-ucer-button:hover {
  background-color: #e65c00;
  box-shadow: 0 3px 12px rgba(255,102,0,0.5);
}

/* Intro Fade-in */
@keyframes fadeInIntro {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/******************************************************
  OYUN EKRANI 
******************************************************/

.kayan-yazilar-ucer-gameplay {
  padding: 50px 20px;
}

/* Kontroller (eğer varsa) */
.kayan-yazilar-ucer-game-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.kayan-yazilar-ucer-game-controls > div {
  margin: 10px;
}

.kayan-yazilar-ucer-game-controls label {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
}

/* Metin Gösterimi */
.kayan-yazilar-ucer-game-display {
  background-color: var(--card-background);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  font-size: 1.5rem;
  line-height: 1.5;
}



/* Kelimelerin fade-in animasyonu */
.kayan-yazilar-ucer-word {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/******************************************************
  OYUN BUTONLARI
******************************************************/
.kayan-yazilar-ucer-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.kayan-yazilar-ucer-controls button {
  background-color: var(--button-background);
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.kayan-yazilar-ucer-controls button:hover {
  background-color: var(--button-hover);
}

/******************************************************
  MESAJ
******************************************************/
.kayan-yazilar-ucer-message {
  font-size: 1.5rem;
  margin-top: 20px;
  font-weight: 700;
  text-align: center;
  color: var(--text-color);
}

/******************************************************
  PROGRESS BAR
******************************************************/
.kayan-yazilar-ucer-progress-bar-container {
  width: 80%;
  max-width: 1200px;
  background-color: var(--progress-bar-background);
  border-radius: 10px;   
  overflow: hidden;
  margin: 20px auto;
  border: 2px solid var(--border-color);
  box-shadow: 0 0 10px var(--border-color);
  height: 30px;  
  letter-spacing: 1px;  
}

.kayan-yazilar-ucer-progress-bar-fill {
  height: 100%;
  background-color: var(--progress-bar-fill);
  width: 0%;
  transition: width 0.5s ease;
  letter-spacing: 1px;
}

/******************************************************
  RESPONSIVE MEDIA QUERIES
******************************************************/

/* 768px ve aşağısı (Mobil) */
@media (max-width: 768px) {
  .kayan-yazilar-ucer-card-body {
    padding: 20px;
  }
  
  .kayan-yazilar-ucer-title {
    font-size: 2rem;
  }

  .kayan-yazilar-ucer-button {
    width: 80%;
  }

  .kayan-yazilar-ucer-game-controls {
    flex-direction: column;
  }

  .kayan-yazilar-ucer-game-controls > div {
    width: 100%;
  }
}

/* 1024px ve ÜSTÜ (Büyük Ekranlar) */
@media (min-width: 1024px) {
  .kayan-yazilar-ucer-intro-container,
  .kayan-yazilar-ucer-game-container {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .kayan-yazilar-ucer-intro {
    padding: 60px 0;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .kayan-yazilar-ucer-card {
    max-width: 1000px;
  }

  .kayan-yazilar-ucer-card-body {
    padding: 50px;
  }

  .kayan-yazilar-ucer-title {
    font-size: 3rem;
  }

  .kayan-yazilar-ucer-button {
    font-size: 1.2rem;
    padding: 15px 40px;
    margin: 10px;
  }

  .kayan-yazilar-ucer-gameplay {
    flex: 1;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .kayan-yazilar-ucer-game-display {
    max-height: 500px;
    font-size: 1.8rem;
  }

  .kayan-yazilar-ucer-message {
    font-size: 2rem;
  }
}

